<template>
    <b-col sm="12" md="6" lg="4">
        <b-card @click="$router.push(`/ulisting/view?id=${id}`)" class="clickable">
            <!-- Card body -->
            <b-card-body class="p-0">
                <b-row class="">
                    <b-col class="text-right">
                        <!-- SOLD sign -->
                        <!-- <badge
                            :rounded="true"
                            size="md"
                            type="success"
                            v-if="status.name == 'SOLD'"
                            >{{ status.friendly_name }}</badge
                        > -->
                    </b-col>
                </b-row>

                <b-row v-if="image_url">
                    <b-col class="p-0">
                        <div class="tagged-image-container">
                            <b-img
                                center
                                :src="image_url"
                                rounded
                                alt="Rounded image"
                                fluid
                                fluid-grow
                                width="250px"
                            >
                            </b-img>
                            <badge
                                :rounded="true"
                                size="md"
                                type="default"
                                :classes="{ 'image-tag': true }"
                                v-if="['SOLD', 'UNDER_OFFER'].includes(status.name)"
                                >{{ status.friendly_name }}
                            </badge>
                        </div>
                    </b-col>
                </b-row>
                <!-- Name -->
                <b-row class="pt-2 pb-0">
                    <b-col>
                        <h2 class="mb-0">{{ name }}</h2>
                    </b-col>
                </b-row>
                <!-- Type -->
                <b-row>
                    <b-col>
                        <b-row>
                            <b-col>
                                <small class="text-muted">
                                    {{ type }}
                                </small>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>

                <!-- Location -->
                <b-row class="pb-2">
                    <b-col>
                        <small class="text-muted">
                            <i class="ni ni-pin-3"></i>
                        </small>
                        <small class="text-muted" v-if="address.suburb">
                            {{ address.suburb }}
                        </small>
                        <small
                            class="text-muted"
                            v-if="address.city && address.suburb"
                        >
                            ,
                        </small>
                        <small class="text-muted" v-if="address.city">
                            {{ address.city }}
                        </small>
                    </b-col>
                </b-row>
                <!-- Tags -->
                <b-row class="pb-2">
                    <b-col>
                        <badge
                            :key="tag.id"
                            v-for="tag in tags"
                            class="mr-1"
                            :rounded="true"
                            size="md"
                            :type="tag.type"
                            >{{ tag.text }}</badge
                        >
                    </b-col>
                </b-row>
                <!-- Prices -->
                <b-row class="pb-2">
                    <b-col class="">
                        <b-row>
                            <b-col>
                                <small class="font-weight-bold m-0">
                                    Rental Income
                                </small>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <h1 class="font-weight-bold m-0 text-orange">
                                    {{ cashflow.symbol
                                    }}{{ formatted_currency(cashflow.amount) }}
                                </h1>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col>
                        <b-row>
                            <b-col>
                                <small class="font-weight-bold m-0">
                                    Negotiated Price
                                </small>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <h1 class="font-weight-bold m-0 text-gray">
                                    {{ buying_price.symbol
                                    }}{{
                                        formatted_currency(buying_price.amount)
                                    }}
                                </h1>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>

                <!-- Bed & Bath -->
                <b-row>
                    <b-col sm="3" md="2" class="ml-3 p-0" v-if="bedrooms > 0">
                        <small class="text-default">
                            <i class="fa fa-bed"></i>
                            {{ bedrooms }}
                        </small>
                    </b-col>
                    <b-col
                        sm="3"
                        md="2"
                        class="p-md-0 pl-sm-3"
                        v-if="bathrooms > 0"
                    >
                        <small class="text-default">
                            <i class="fa fa-bath"></i>
                            {{ bathrooms }}
                        </small>
                    </b-col>
                </b-row>
                <!-- Area -->
                <b-row>
                    <b-col sm="3" class="ml-3 p-0" v-if="size_m2 > 0">
                        <small class="text-default">
                            <i class="fa fa-home"></i>
                            size_m2&#13217;
                        </small>
                    </b-col>
                </b-row>
                <!-- <b-button pill variant="success" size="sm"> View </b-button> -->
            </b-card-body>
        </b-card>
    </b-col>
</template>
<script>
import Badge from "@/components/Badge.vue";
export default {
    name: "DiscoverListingFeatureCard",
    components: {
        Badge,
    },
    props: {
        id: {
            type: String,
            description: "ID of Listing",
            default: "",
        },

        name: {
            type: String,
            description: "Name",
            default: "Hadelle Heights",
        },
        type: {
            type: String,
            description: "Type",
            default: "",
        },
        image_url: {
            type: String,
            description: "URL of image",
            default: "",
        },
        address: {
            type: Object,
            description: "Display Adress",
            default(rawProps) {
                return { city: "", suburb: "" };
            },
        },
        bedrooms: {
            type: Number,
            description: "Number of bedrooms",
            default: 0,
        },
        bathrooms: {
            type: Number,
            description: "Number of bathrooms",
            default: 0,
        },
        cashflow: {
            type: Object,
            description: "Name",
            default(rawProps) {
                return { symbol: "R", amount: 0 };
            },
        },
        buying_price: {
            type: Object,
            description: "Name",
            default(rawProps) {
                return { symbol: "R", amount: 0 };
            },
        },
        size_m2: {
            type: Number,
            description: "Livable size",
            default: 0,
        },
        status: {
            type: Object,
            description: "The status of the listing",
            default() {
                return {
                    name: null,
                    friendly_name: null,
                };
            },
        },
        tags: {
            type: Array,
            description: "List of tags",
            default(rawProps) {
                return [
                    {
                        text: "New",
                        type: "primary",
                    },

                    {
                        text: "New",
                        type: "primary",
                    },
                    {
                        text: "New",
                        type: "primary",
                    },
                    {
                        text: "New",
                        type: "primary",
                    },
                ];
            },
        },
    },
    data() {
        return {};
    },
    methods: {
        formatted_currency(value) {
            return value.toLocaleString();
        },
    },
};
</script>
<style>
.tagged-image-container {
    position: relative;
    display: inline-block;
}
.image-tag {
    position: absolute;
    right: 1rem;
    top: 1rem;
}
</style>
