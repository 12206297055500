<template>
    <b-row v-if="rating != 0">
        <!-- sm="12" md="4" -->
        <b-col v-if="align_right"></b-col>
        <b-col  class="text-nowrap col-md-auto pr-0">
            <h6>
                <rating-stars-comp
                    v-model="rating"
                    numeric_rating_before
                    disabled
                ></rating-stars-comp>
            </h6>
        </b-col>
        <!--  ml-lg--5 ml-xl--8  -->
        <b-col
            md="2"
            class="text-left text-nowrap pl-1 col-md-auto"
            v-if="rating_count"
        >
            <h5 v-if="rating_count == 1" class="text-success mt-1">
                {{ rating_count }} Rating
            </h5>
            <h5 v-else class="text-success mt-1">{{ rating_count }} Ratings</h5>
        </b-col>
        <!-- <b-col class="ml-lg--3 ml-xl--5">
            <base-button type="default" size="sm" :pill="true">
                Rate
            </base-button>
        </b-col> -->
    </b-row>
</template>

<script>
// Compoonents
import RatingStarsComp from "@/views/Components/Rating/RatingStarsComp.vue";

export default {
    name: "RatingViewWidget",
    components: {
        RatingStarsComp,
    },
    props: {
        rating: {
            type: Number,
            description: "The rating score",
            default: 0,
        },
        service_provier_id: {
            type: String,
            description: "The service provider ID",
            default: null,
        },
        rating_count: {
            type: Number,
            description: "The numbner of ratings",
            default: 0,
        },
        rating_id: {
            type: String,
            description: "The id of the rating",
            default: null,
        },
        align_right:{
            type: Boolean, 
            description: "Align right", 
            default: false, 
        }
    },
};
</script>

<style></style>
