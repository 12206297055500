<template>
    <div>
        <b-card>
            <!-- Card body -->
            <b-card-body class="p-0">
                <!-- <b-card-title title-tag="h5" class="h1 mb-0"
                    >Get started with Argon</b-card-title
                > -->
                <b-row>
                    <!-- Title -->
                    <b-col sm="3">
                        <b-row>
                            <b-col>
                                <h1 class="font-weight-bold mb-0">
                                    {{ name }}
                                </h1>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <small
                                    class="text-muted"
                                    v-if="creator_first_name"
                                >
                                    Lister: {{ creator_first_name }}
                                    {{ creator_last_name }}
                                </small>
                                <small class="text-muted" v-else>
                                    Lister: Unknown
                                </small>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <small class="text-muted">
                                    <i class="fa-solid fa-house"></i>
                                    {{ type }}
                                </small>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <small class="text-muted">
                                    <i class="ni ni-pin-3"></i>
                                    {{ address }}
                                </small>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col sm="1" lg="2">
                        <b-row class="pt-2">
                            <b-col class="px-0">
                                <badge
                                    :rounded="true"
                                    size="md"
                                    :type="status_type"
                                    class="mx-1"
                                    >{{ status }}
                                </badge>
                                <badge
                                    v-if="
                                        approval_status || approval_status.name
                                    "
                                    class="mx-1"
                                    :rounded="true"
                                    size="md"
                                    :type="approval_status_style"
                                    >{{ approval_status.friendly_name }}</badge
                                >
                                <badge
                                    class="mx-1"
                                    :key="tag.text"
                                    v-for="tag in tags"
                                    :rounded="true"
                                    size="md"
                                    :type="tag.type"
                                    >{{ tag.text }}</badge
                                >
                            </b-col>
                        </b-row>
                    </b-col>
                    <!-- Bed and bath -->
                    <b-col sm="3" lg="2">
                        <b-row class="mt-3">
                            <b-col v-if="bedrooms" lg="4" class="p-0">
                                <p class="text-muted font-weight-bold">
                                    <i class="fa fa-bed"></i>
                                    {{ bedrooms }}
                                </p>
                            </b-col>
                            <b-col v-if="bathrooms" lg="4" class="p-0">
                                <p class="text-muted font-weight-bold">
                                    <i class="fa fa-bath"></i>
                                    {{ bathrooms }}
                                </p>
                            </b-col>
                        </b-row>
                    </b-col>
                    <!-- Cash Stats -->
                    <b-col sm="5">
                        <b-row>
                            <b-col>
                                <div v-if="cashflow.amount != 0">
                                    <b-row
                                        ><b-col>
                                            <p class="mb--2 font-weight-bold">
                                                Rental Income
                                            </p>
                                        </b-col></b-row
                                    ><b-row
                                        ><b-col class="ml-2">
                                            <h1
                                                class="text-orange font-weight-bold m-0 text-nowrap"
                                            >
                                                {{ cashflow.symbol }}
                                                {{
                                                    formatted_currency(
                                                        cashflow.amount
                                                    )
                                                }}
                                                <!-- R 4,500 -->
                                            </h1>
                                        </b-col></b-row
                                    >
                                </div>
                            </b-col>
                            <b-col>
                                <div v-if="buying_price.amount != 0">
                                    <b-row
                                        ><b-col>
                                            <p class="mb--2 font-weight-bold">
                                                Negotiated Price
                                            </p>
                                        </b-col>
                                    </b-row>
                                    <b-row
                                        ><b-col class="ml-2">
                                            <h1
                                                class="text-black-50 font-weight-bold m-0 text-nowrap"
                                            >
                                                {{ buying_price.symbol }}
                                                {{
                                                    formatted_currency(
                                                        buying_price.amount
                                                    )
                                                }}
                                                <!-- R 350,500 -->
                                            </h1>
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col></b-col>
                            <b-col class="text-right col-md-auto px-0">
                                <router-link
                                    :to="{
                                        path: `/listing/manage/${id}`,
                                    }"
                                    v-if="id"
                                >
                                    <base-button
                                        type="default"
                                        :pill="true"
                                        class="mt-4"
                                    >
                                        View
                                    </base-button>
                                </router-link>
                            </b-col>
                            <b-col class="text-right col-md-auto px-0 mt-3">
                                <b-dropdown
                                    size="lg"
                                    variant="link"
                                    toggle-class="text-decoration-none"
                                    no-caret
                                    dropleft
                                >
                                    <template #button-content>
                                        <i
                                            class="fas fa-ellipsis-v text-muted"
                                        ></i>
                                    </template>
                                    <b-dropdown-item
                                        :to="{
                                            path: `/listing/edit?id=${id}`,
                                        }"
                                        >Edit Detail</b-dropdown-item
                                    >
                                    <b-dropdown-item
                                        :to="{
                                            path: `/listing/manage-investments?lid=${id}`,
                                        }"
                                        >Edit Investment</b-dropdown-item
                                    >
                                </b-dropdown>
                            </b-col>

                            <!-- <b-col class="text-right">
                                <router-link
                                    :to="{
                                        path: '/listing/edit',
                                        query: {
                                            id: id,
                                        },
                                    }"
                                >
                                    <base-button
                                        type="default"
                                        size="sm"
                                        :pill="true"
                                        class="mt-4 mx-1"
                                    >
                                        Edit
                                    </base-button>
                                </router-link>
                                <router-link
                                    :to="{
                                        path: '/listing/manage-investments',
                                        query: {
                                            lid: id,
                                        },
                                    }"
                                >
                                    <base-button
                                        type="primary"
                                        size="sm"
                                        :pill="true"
                                        class="mt-4 mx-1"
                                    >
                                        Investment
                                    </base-button>
                                </router-link>
                                <router-link
                                    :to="{
                                        path: '/listing/view',
                                        query: {
                                            id: id,
                                            preview: true,
                                        },
                                    }"
                                    v-if="id"
                                >
                                    <base-button
                                        type="default"
                                        size="sm"
                                        :pill="true"
                                        class="mt-4 mx-1"
                                    >
                                        Preview
                                    </base-button>
                                </router-link>
                            </b-col> -->
                        </b-row>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
    </div>
</template>
<script>
import Badge from "@/components/Badge.vue";
export default {
    name: "ListingDashboardItem",
    components: {
        Badge,
    },
    props: {
        id: {
            type: String,
            description: "",
            default: "",
        },
        creator_first_name: {
            type: String,
            description: "Name of the Creator",
            default: "",
        },
        creator_last_name: {
            type: String,
            description: "Last Name of the Creator",
            default: "",
        },
        name: {
            type: String,
            description: "Name",
            default: "",
        },
        status: {
            type: String,
            description: "Status",
            default: "",
        },
        approval_status: {
            type: Object,
            description: "Approval Status",
            default() {
                return {
                    id: "",
                    friendly_name: "",
                    name: "",
                };
            },
        },
        type: {
            type: String,
            description: "Type",
            default: "",
        },
        address: {
            type: String,
            description: "Display Adress",
            default: "",
        },
        bedrooms: {
            type: Number,
            description: "Number of bedrooms",
            default: 0,
        },
        bathrooms: {
            type: Number,
            description: "Number of bathrooms",
            default: 0,
        },
        cashflow: {
            type: Object,
            description: "Name",
            default(rawProps) {
                return { symbol: "R", amount: 0 };
            },
            // default: { symbol: "R", amount: 0 },
        },
        buying_price: {
            type: Object,
            description: "Name",
            default(rawProps) {
                return { symbol: "R", amount: 0 };
            },
        },
        tags: {
            type: Array,
            description: "List of tags",
            default(rawProps) {
                return [];
            },
        },
    },
    data() {
        return {};
    },
    methods: {
        formatted_currency(value) {
            return value.toLocaleString();
        },
    },
    computed: {
        status_type() {
            if (this.status == "Published") {
                return "success";
            } else {
                return "info";
            }
        },
        approval_status_style() {
            if (this.approval_status.name == "SUBMITTED") {
                return "danger";
            }
            if (this.approval_status.name == "PENDING_SUBMISSION") {
                return "info";
            }
            if (this.approval_status.name == "NEEDS_REVIEW") {
                return "info";
            }
            if (this.approval_status.name == "REJECTED") {
                return "warning";
            }
            if (this.approval_status.name == "APPROVED") {
                return "success";
            }
        },
    },
};
</script>
<style></style>
