<template>
    <b-card>
        <!-- Card body -->
        <b-card-body class="p-0">
            <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
                <b-form @submit.prevent="handleSubmit(on_submit)">
                    <input type="submit" ref="form_submit_button" hidden />
                    <h6 class="heading-small text-muted mb-4">
                        Property information
                        <Transition>
                            <i
                                v-if="$apollo.loading || form.is_loading"
                                class="fas fa-spinner fa-spin"
                            ></i>
                        </Transition>
                    </h6>

                    <div class="pl-lg-4">
                        <!-- Basic Information -->
                        <div>
                            <b-row>
                                <b-col sm="6" md="3">
                                    <base-input
                                        label="Bedrooms*"
                                        type="number"
                                        class="mb-3"
                                        placeholder="No. Bedrooms"
                                        name="Bedrooms"
                                        step="0.1"
                                        v-model="form.bedrooms"
                                        :rules="{
                                            required: true,
                                        }"
                                        :disabled="main_subunit_id === null"
                                        @keyup="input_debounced"
                                    >
                                    </base-input>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col sm="6" md="3">
                                    <base-input
                                        label="Bathrooms*"
                                        type="number"
                                        class="mb-3"
                                        placeholder="No. Bathrooms"
                                        name="Bathrooms"
                                        step="0.1"
                                        v-model="form.bathrooms"
                                        :rules="{
                                            required: true,
                                        }"
                                        :disabled="main_subunit_id === null"
                                        @keyup="input_debounced"
                                    >
                                    </base-input>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </b-form>
            </validation-observer>
        </b-card-body>
    </b-card>
</template>

<script>
// Modules
import { debounce } from "debounce";

// Queries
import { GET_PROPERTY_SUBUNIT_FULL_DETAIL } from "@/graphql/queries";
import { GET_PROPERTY_LISTING_SUBUNITS_LIGHT } from "@/graphql/queries";

// Mutations
import { UPDATE_PROPERTY_SUBUNIT } from "@/graphql/mutations";

export default {
    name: "ListingSubunitDetailComponent",
    components: {},
    props: {
        listing_id: {
            type: String,
            description: "ID of the listing.",
            default: null,
        },
    },
    apollo: {
        // We might consider moving this out to a parent component and
        // have the subunit id passed in.
        get_main_subunit_id: {
            query: GET_PROPERTY_LISTING_SUBUNITS_LIGHT,
            result(data) {
                this.handle_get_main_subunit_id(data);
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            update(data) {
                this.apollo_data.get_main_subunit_id = data;
            },
            variables() {
                return {
                    listing_id: this.listing_id,
                };
            },
            skip: true,
        },
        get_subunit_full_detail: {
            query: GET_PROPERTY_SUBUNIT_FULL_DETAIL,
            result(data) {
                this.handle_get_subunit_full_detail(data);
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            update(data) {
                this.apollo_data.get_subunit_full_detail = data;
            },
            variables() {
                return {
                    subunit_id: this.main_subunit_id,
                };
            },
            skip: true,
        },
    },
    data() {
        return {
            main_subunit_id: null,
            apollo_data: {
                get_main_subunit_id: null,
                get_subunit_full_detail: null,
            },

            form: {
                is_loading: false,
                bedrooms: null,
                bathrooms: null,
            },
        };
    },
    methods: {
        // Form handlers
        input_debounced: debounce(function () {
            this.form.is_loading = true;
            this.$refs.form_submit_button.click();
        }, 500),

        on_submit() {
            if (this.subunit_id !== null) {
                this.update_subunit();
            }
        },

        update_subunit() {
            this.$apollo
                .mutate({
                    mutation: UPDATE_PROPERTY_SUBUNIT,
                    variables: {
                        subunit_id: this.main_subunit_id,
                        bathroom_count: this.form.bathrooms,
                        bedroom_count: this.form.bedrooms,
                    },
                })
                .then((res) => {
                    this.form.is_loading = false;
                    return res;
                })
                .catch((res) => {
                    this.form.is_loading = false;
                });
        },

        // Apollo Handlers
        handle_get_subunit_full_detail(data) {
            this.form.bathrooms = this.show_rounded_count(
                data.data.propertySubunit.bathroomCount
            );
            this.form.bedrooms = this.show_rounded_count(
                data.data.propertySubunit.bedroomCount
            );
        },
        handle_get_main_subunit_id(data) {
            let flattened = graph_utils.flatten_objects_recursive(
                graph_utils.apollo_to_obj_recursive(data.data)
            );
            for (let i = 0; i < flattened.allPropertySubunit.length; i++) {
                if (
                    flattened.allPropertySubunit[i].subunitType__name == "MAIN"
                ) {
                    this.main_subunit_id = flattened.allPropertySubunit[i].id;
                }
            }
        },

        //  Apollo managers
        enable_get_main_subunit_id() {
            if (!this.$apollo.queries.get_main_subunit_id.skip) {
                this.$apollo.queries.get_main_subunit_id.refetch();
            } else {
                this.$apollo.queries.get_main_subunit_id.skip = false;
                this.$apollo.queries.get_main_subunit_id.start();
            }
            if (!this.$apollo.queries.get_main_subunit_id.skip) {
                this.$apollo.queries.get_main_subunit_id.refetch();
            } else {
                this.$apollo.queries.get_main_subunit_id.skip = false;
                this.$apollo.queries.get_main_subunit_id.start();
            }
        },
        enable_get_subunit_full_detail() {
            if (!this.$apollo.queries.get_subunit_full_detail.skip) {
                this.$apollo.queries.get_subunit_full_detail.refetch();
            } else {
                this.$apollo.queries.get_subunit_full_detail.skip = false;
                this.$apollo.queries.get_subunit_full_detail.start();
            }
            if (!this.$apollo.queries.get_subunit_full_detail.skip) {
                this.$apollo.queries.get_subunit_full_detail.refetch();
            } else {
                this.$apollo.queries.get_subunit_full_detail.skip = false;
                this.$apollo.queries.get_subunit_full_detail.start();
            }
        },
        // Utils
        show_rounded_count(value) {
            return (Math.round(value * 100) / 100).toFixed(1);
        },
    },
    watch: {
        listing_id() {
            if (this.listing_id !== null) {
                this.enable_get_main_subunit_id();
            }
        },
        main_subunit_id() {
            if (this.main_subunit_id !== null) {
                this.enable_get_subunit_full_detail();
            }
        },
    },
    mounted() {
        if (this.listing_id !== null) {
            this.enable_get_main_subunit_id();
            setTimeout(() => {
                this.enable_get_main_subunit_id();
            }, 2000);
        }
    },
};
</script>

<style></style>
