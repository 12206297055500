var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-card-body',{staticClass:"p-0"},[_c('validation-observer',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.on_submit)}}},[_c('input',{ref:"form_submit_button",attrs:{"type":"submit","hidden":""}}),_c('h6',{staticClass:"heading-small text-muted mb-4"},[_vm._v(" Estimated Refurbishment Costs "),_c('Transition',[(_vm.$apollo.loading || _vm.form_is_loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e()])],1),_c('div',{staticClass:"pl-lg-4"},[_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-checkbox',{staticClass:"mb-3",on:{"change":_vm.input_debounced},model:{value:(_vm.renovations.planned),callback:function ($$v) {_vm.$set(_vm.renovations, "planned", $$v)},expression:"renovations.planned"}},[_vm._v(" Planned renovations or improvements? ")])],1)],1),_c('Transition',[(_vm.renovations.planned)?_c('div',[_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('base-input',{staticClass:"mb-3",attrs:{"label":"Description*","placeholder":"Enter description of the renovation / improvement.","name":"Description","rules":{
                      required: _vm.renovations.planned,
                      min: 15,
                    },"type":"textarea","rows":"6"},on:{"keyup":_vm.input_debounced},model:{value:(_vm.renovations.description),callback:function ($$v) {_vm.$set(_vm.renovations, "description", $$v)},expression:"renovations.description"}})],1),_c('b-col',{attrs:{"lg":"6"}})],1),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('base-input',{attrs:{"label":"Type","name":"Type","rules":{
                      required: _vm.renovations.planned,
                    }}},[_c('el-select',{attrs:{"filterable":"","placeholder":"Select"},on:{"change":function($event){_vm.form_is_loading = true;
                        _vm.$refs.form_submit_button.click();}},model:{value:(_vm.renovations.type.selection),callback:function ($$v) {_vm.$set(_vm.renovations.type, "selection", $$v)},expression:"renovations.type.selection"}},_vm._l((_vm.renovations.type.options),function(option){return _c('el-option',{key:option.label,attrs:{"label":option.label,"value":option.value}})}),1)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"4"}},[_c('base-input',{staticClass:"mb-3",attrs:{"label":"Projected Cost (R)*","type":"number","placeholder":"Total cost","name":"Projected Cost","rules":{
                      required: _vm.renovations.planned,
                    }},on:{"keyup":_vm.input_debounced},model:{value:(_vm.renovations.projected_cost),callback:function ($$v) {_vm.$set(_vm.renovations, "projected_cost", $$v)},expression:"renovations.projected_cost"}})],1)],1)],1):_vm._e()])],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }