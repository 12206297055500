<template>
  <div>
    <div class="header pb-6 d-flex align-items-center">
      <!-- Mask -->
      <span class="mask bg-white opacity-8"></span>
      <!-- Header container -->
      <b-container fluid class="d-flex align-items-center">
        <b-row>
          <b-col lg="12">
            <h1 class="display-1 text-orange pb-0 mb-0">Manage Listings</h1>
            <p class="text-default bold mt-0 mb-5 font-weight-bold">
              Let's make sure we have quality on the platform!
            </p>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <b-container class="mt--6">
      <b-row class="mb-4">
        <b-col sm="11"></b-col>

        <!-- Search -->
        <b-col sm="3">
          <b-form
            class="navbar-search form-inline mr-sm-3 navbar-search-light"
            id="navbar-search-main"
            @submit.prevent="onSearchSubmit"
          >
            <b-form-group class="mb-0">
              <b-input-group class="input-group-alternative input-group-merge">
                <div class="input-group-prepend">
                  <span class="input-group-text"
                    ><i class="fas fa-search"></i
                  ></span>
                </div>
                <b-form-input
                  placeholder="Search"
                  type="text"
                  v-model="searchTerm"
                  @keyup="onKeyUpSearch"
                >
                </b-form-input>
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i
                      v-if="isSearchLoading"
                      class="fas fa-spinner fa-spin text-muted"
                    ></i>
                  </span>
                </div>
                <input type="submit" ref="searchFormSubmit" hidden />
              </b-input-group>
            </b-form-group>

            <button
              type="button"
              class="close"
              data-action="search-close"
              data-target="#navbar-search-main"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </b-form>
        </b-col>
      </b-row>
      <b-row
        v-if="propertyListings.length == 0 && !$apollo.loading"
        class="mt-7"
      >
        <b-col class="text-center">
          <h3 class="text-default">No listings found to manage</h3>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="12">
          <div
            v-for="property_listing in propertyListings"
            :key="property_listing.id"
          >
            <transition>
              <listing-manage-item
                :id="property_listing.id"
                :name="property_listing.name"
                :creator_first_name="property_listing.creator.first_name"
                :creator_last_name="property_listing.creator.last_name"
                :address="property_listing.address"
                :cashflow="property_listing.cashflow"
                :buying_price="property_listing.buying_price"
                :bathrooms="property_listing.bathrooms"
                :bedrooms="property_listing.bedrooms"
                :tags="property_listing.tags"
                :type="property_listing.type"
                :status="property_listing.status"
                :approval_status="property_listing.approval_status"
              ></listing-manage-item>
            </transition>
          </div>
          <b-col sm="12">
            <h1 class="text-center">
              <i
                v-if="$apollo.loading || isLoading"
                class="fas fa-spinner fa-spin"
              ></i>
            </h1>
          </b-col>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { debounce } from "debounce";
import ListingManageItem from "@/views/Components/Listing/ListingManageItem.vue";
import { GET_ALL_PROPERTY_LISTING_MANAGE_VIEW } from "@/graphql/queries";

export default {
  name: "MyListings",
  components: {
    ListingManageItem,
  },
  data() {
    return {
      propertyListings: [],
      offset: 0,
      searchTerm: "",
      isSearchLoading: false,
      isLoading: false,
      allListingsLoaded: false,
    };
  },
  apollo: {
    allPropertyListing: {
      query: GET_ALL_PROPERTY_LISTING_MANAGE_VIEW,
      variables() {
        return {
          first: 25,
          offset: this.offset,
          search: this.searchTerm || "",
        };
      },
      result({ data }) {
        const newListings = this.handleListings(
          data.allPropertyListing,
          this.offset
        );
        if (newListings.length < 25) {
          this.allListingsLoaded = true;
        }
        this.isLoading = false;
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name);
        console.log(errors.graphQLErrors);
        return false;
      },
    },
  },
  methods: {
    async fetchListings(offset = 0) {
      if (this.allListingsLoaded) {
        return;
      }
      this.isLoading = true;

      try {
        const observableQuery = this.$apollo.watchQuery({
          query: GET_ALL_PROPERTY_LISTING_MANAGE_VIEW,
          variables: {
            first: 25,
            offset: offset,
            search: this.searchTerm || "",
          },
          fetchPolicy: "cache-and-network",
        });

        const subscription = observableQuery.subscribe({
          next: ({ data }) => {
            const newListings = this.handleListings(
              data.allPropertyListing,
              offset
            );

            if (newListings.length < 25) {
              this.allListingsLoaded = true;
            }

            this.isLoading = false;
          },
          error: (error) => {
            console.error("Error fetching listings:", error);
            this.isLoading = false;
          },
        });

        // Cleanup subscription on component unmount
        this.$on("hook:beforeDestroy", () => {
          subscription.unsubscribe();
        });
      } catch (error) {
        console.error("Error initializing fetch:", error);
        this.isLoading = false;
      }
    },
    handleListings(listings, offset) {
      if (!listings) return [];
      const newListings = graph_utils
        .flatten_relay_response(listings)
        .map((listing) => ({
          id: listing.id,
          name: listing.name,
          creator: {
            first_name: listing.listerUser__firstName,
            last_name: listing.listerUser__lastName,
            username: listing.listerUser__username,
          },
          address: listing.address__gAddress,
          buying_price: {
            symbol: listing.buyingPrice__currency_symbol || "R",
            amount: listing.buyingPrice__amount,
          },
          cashflow: {
            symbol: listing.totalExpectedRentalIncome__currency_symbol || "R",
            amount: listing.totalExpectedRentalIncome__amount,
          },
          bathrooms: 0,
          bedrooms: 0,
          tags: [],
          type: listing.listingType__name,
          status: listing.status__friendlyName,
          approval_status:
            "approval__id" in listing
              ? {
                  id: listing.approval__id,
                  friendly_name: listing.approval__state__friendlyName,
                  name: listing.approval__state__name,
                }
              : null,
        }));

      if (offset === 0) {
        this.propertyListings = newListings;
      } else {
        this.propertyListings = [...this.propertyListings, ...newListings];
      }
      this.offset = offset;
      return newListings;
    },
    onKeyUpSearch: debounce(function () {
      this.isSearchLoading = true;
      this.$refs.searchFormSubmit.click();
    }, 1000),
    onSearchSubmit() {
      this.allListingsLoaded = false;
      this.offset = 0;
      this.fetchListings().then(() => {
        this.isSearchLoading = false;
      });
    },
    onScroll() {
      const scrollPosition = window.scrollY + window.innerHeight;
      const threshold = document.documentElement.scrollHeight * 0.75;

      if (
        scrollPosition >= threshold &&
        !this.isLoading &&
        !this.allListingsLoaded
      ) {
        this.fetchListings(this.offset + 25);
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
    this.$apollo.queries.allPropertyListing.setOptions({
      fetchPolicy: "network-only",
    });
    this.$apollo.queries.allPropertyListing.refetch();
  },
  created() {
    global_event_emitter.$on("listings_update", () => {
      this.$apollo.queries.allPropertyListing.refetch();
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
    global_event_emitter.$off("listings_update");
  },
};
</script>

<style>
.profile-header {
  background-image: url(/img/theme/profile-cover.jpg);
  background-size: cover;
  background-position: center top;
  min-height: 500px;
}
</style>
