var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('confirmation-modal',{attrs:{"confirmation_message":_vm.modals.confirmation_delete.confirmation_message,"confirmation_sub_message":_vm.modals.confirmation_delete.confirmation_sub_message},on:{"confirmed":_vm.handle_confirmation_delete_modal},model:{value:(_vm.modals.confirmation_delete.show),callback:function ($$v) {_vm.$set(_vm.modals.confirmation_delete, "show", $$v)},expression:"modals.confirmation_delete.show"}}),_c('div',[_c('b-modal',{staticClass:"p-0",attrs:{"size":"lg","id":"crud-room-modal","title":_vm.create_mode ? _vm.title_options['c'] : _vm.title_options['rud'],"ok-title":"Save"},on:{"hidden":function($event){return _vm.handle_modal_hide()},"ok":_vm.handle_delete_button,"cancel":_vm.handle_ok},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [(_vm.internal_subunit_id)?_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){return ok()}}},[_vm._v(" Delete ")]):_vm._e(),_c('b-button',{attrs:{"variant":"default"},on:{"click":function($event){return cancel()}}},[_vm._v(" Save ")])]}}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('b-card',{staticClass:"p-0"},[_c('b-card-body',{staticClass:"p-0"},[_c('h6',{staticClass:"heading-small text-muted mt-3"},[_vm._v(" Basic Detail "),_c('Transition',[(_vm.$apollo.loading && !_vm.create_mode)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e()])],1),_c('validation-observer',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.on_submit)}}},[_c('input',{ref:"room_basic_detail_submit_button",attrs:{"type":"submit","hidden":""}}),_c('div',{staticClass:"pl-lg-4"},[_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('base-input',{staticClass:"mb-3",attrs:{"label":"Room Name*","placeholder":"Enter a name for this room.","name":"Room Name","rules":{ required: true },"disabled":_vm.$apollo.loading &&
                                                !_vm.create_mode},on:{"keyup":_vm.debounced_on_keyup_basic_detail},model:{value:(
                                                _vm.form.basic_detail.room_name
                                            ),callback:function ($$v) {_vm.$set(_vm.form.basic_detail, "room_name", $$v)},expression:"\n                                                form.basic_detail.room_name\n                                            "}})],1),_c('b-col',{attrs:{"lg":"6"}})],1),_c('b-row',[_c('b-col',{attrs:{"sm":"6","lg":"3"}},[_c('base-input',{staticClass:"mb-3",attrs:{"label":"Room Size m2","type":"number","placeholder":"size","name":"Room Size","rules":{ required: false },"disabled":_vm.$apollo.loading &&
                                                !_vm.create_mode},on:{"keyup":_vm.debounced_on_keyup_basic_detail},model:{value:(
                                                _vm.form.basic_detail.room_size
                                            ),callback:function ($$v) {_vm.$set(_vm.form.basic_detail, "room_size", $$v)},expression:"\n                                                form.basic_detail.room_size\n                                            "}})],1),_c('b-col',{attrs:{"lg":"6"}})],1),_c('b-row',{staticClass:"pt-3"},[_c('b-col',{attrs:{"lg":"6"}},[_c('Transition',[(
                                                    !_vm.form.basic_detail
                                                        .to_be_partitioned
                                                )?_c('b-form-checkbox',{staticClass:"mb-3",attrs:{"disabled":_vm.$apollo.loading &&
                                                    !_vm.create_mode},on:{"change":_vm.debounced_on_change_basic_detail},model:{value:(
                                                    _vm.form.basic_detail
                                                        .already_partitioned
                                                ),callback:function ($$v) {_vm.$set(_vm.form.basic_detail
                                                        , "already_partitioned", $$v)},expression:"\n                                                    form.basic_detail\n                                                        .already_partitioned\n                                                "}},[_vm._v(" Already Partitioned? ")]):_vm._e()],1)],1),_c('Transition',[(
                                                !_vm.form.basic_detail
                                                    .already_partitioned
                                            )?_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-checkbox',{staticClass:"mb-3",attrs:{"disabled":_vm.$apollo.loading &&
                                                    !_vm.create_mode},on:{"change":_vm.debounced_on_change_basic_detail},model:{value:(
                                                    _vm.form.basic_detail
                                                        .to_be_partitioned
                                                ),callback:function ($$v) {_vm.$set(_vm.form.basic_detail
                                                        , "to_be_partitioned", $$v)},expression:"\n                                                    form.basic_detail\n                                                        .to_be_partitioned\n                                                "}},[_vm._v(" To be partitioned? ")])],1):_vm._e()],1)],1),_c('b-row',{},[_c('b-col',[_c('b-row',[_c('b-col',[_c('b-form-checkbox',{staticClass:"mb-3",attrs:{"disabled":_vm.$apollo.loading &&
                                                        !_vm.create_mode},on:{"change":_vm.debounced_on_change_basic_detail},model:{value:(
                                                        _vm.form.basic_detail
                                                            .shared_kitchen
                                                    ),callback:function ($$v) {_vm.$set(_vm.form.basic_detail
                                                            , "shared_kitchen", $$v)},expression:"\n                                                        form.basic_detail\n                                                            .shared_kitchen\n                                                    "}},[_vm._v(" Shared Kitchen? ")])],1)],1),_c('b-row',[_c('b-col',[_c('b-form-checkbox',{staticClass:"mb-3",attrs:{"disabled":_vm.$apollo.loading &&
                                                        !_vm.create_mode},on:{"change":_vm.debounced_on_change_basic_detail},model:{value:(
                                                        _vm.form.basic_detail
                                                            .shared_bathroom
                                                    ),callback:function ($$v) {_vm.$set(_vm.form.basic_detail
                                                            , "shared_bathroom", $$v)},expression:"\n                                                        form.basic_detail\n                                                            .shared_bathroom\n                                                    "}},[_vm._v(" Shared Bathroom? ")])],1)],1)],1),_c('b-col',[_c('b-row',[_c('b-col',[_c('b-form-checkbox',{staticClass:"mb-3",attrs:{"disabled":_vm.$apollo.loading &&
                                                        !_vm.create_mode},on:{"change":_vm.debounced_on_change_basic_detail},model:{value:(
                                                        _vm.form.basic_detail
                                                            .furnished
                                                    ),callback:function ($$v) {_vm.$set(_vm.form.basic_detail
                                                            , "furnished", $$v)},expression:"\n                                                        form.basic_detail\n                                                            .furnished\n                                                    "}},[_vm._v(" Furnished? ")])],1)],1)],1)],1)],1)])]}}])})],1)],1),(_vm.internal_subunit_id)?_c('b-card',{staticClass:"p-0"},[_c('b-card-body',{staticClass:"p-0"},[_c('h6',{staticClass:"heading-small text-muted mt-3"},[_vm._v(" Rental Information "),_c('Transition',[(_vm.$apollo.loading && !_vm.create_mode)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e()])],1),_c('validation-observer',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                            var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.on_submit)}}},[_c('input',{ref:"room_rental_information_submit_button",attrs:{"type":"submit","hidden":""}}),_c('div',{staticClass:"pl-lg-4"},[_c('b-row',[_c('b-col',{attrs:{"sm":"6","lg":"4"}},[_c('base-input',{staticClass:"mb-3",attrs:{"label":"Projected Rental Income (R)*","type":"number","placeholder":"Projected Rental Income","name":"Projected Rental Income","rules":{ required: true },"disabled":_vm.$apollo.loading &&
                                                !_vm.create_mode},on:{"keyup":_vm.debounced_on_keyup_rental_information},model:{value:(
                                                _vm.form.rental_information
                                                    .expected_rent
                                            ),callback:function ($$v) {_vm.$set(_vm.form.rental_information
                                                    , "expected_rent", $$v)},expression:"\n                                                form.rental_information\n                                                    .expected_rent\n                                            "}})],1)],1)],1)])]}}],null,false,3236102903)})],1)],1):_vm._e(),_c('Transition',[(_vm.form.rental_information.current_lease)?_c('b-card',{staticClass:"p-0"},[_c('b-card-body',{staticClass:"p-0"},[_c('h6',{staticClass:"heading-small text-muted mt-3"},[_vm._v(" Current Rental Information "),_c('Transition',[(_vm.$apollo.loading && !_vm.create_mode)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e()])],1),_c('validation-observer',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                    var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"pl-lg-4"},[_c('b-row',[_c('b-col',{attrs:{"sm":"6","lg":"4"}},[_c('base-input',{staticClass:"mb-3",attrs:{"label":"Current Rent (R)*","type":"number","placeholder":"Current Rent","name":"Current Rent","rules":{ required: true },"disabled":_vm.$apollo.loading &&
                                                    !_vm.create_mode},model:{value:(
                                                    _vm.form
                                                        .current_rental_information
                                                        .current_rent
                                                ),callback:function ($$v) {_vm.$set(_vm.form
                                                        .current_rental_information
                                                        , "current_rent", $$v)},expression:"\n                                                    form\n                                                        .current_rental_information\n                                                        .current_rent\n                                                "}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"6","lg":"4"}},[_c('base-input',{staticClass:"mb-3",attrs:{"label":"Deposit Amount (R)","type":"number","placeholder":"Deposit Amount","name":"Deposit Amount","rules":{ required: false },"disabled":_vm.$apollo.loading &&
                                                    !_vm.create_mode},model:{value:(
                                                    _vm.form
                                                        .current_rental_information
                                                        .deposit_amount
                                                ),callback:function ($$v) {_vm.$set(_vm.form
                                                        .current_rental_information
                                                        , "deposit_amount", $$v)},expression:"\n                                                    form\n                                                        .current_rental_information\n                                                        .deposit_amount\n                                                "}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"6","lg":"4"}},[_c('base-input',{staticClass:"mb-3",attrs:{"label":"Annual Escalation (%)*","type":"number","placeholder":"Annual Escalation","name":"Annual Escalation","rules":{ required: true },"disabled":_vm.$apollo.loading &&
                                                    !_vm.create_mode},model:{value:(
                                                    _vm.form
                                                        .current_rental_information
                                                        .annual_escalation_percentage
                                                ),callback:function ($$v) {_vm.$set(_vm.form
                                                        .current_rental_information
                                                        , "annual_escalation_percentage", $$v)},expression:"\n                                                    form\n                                                        .current_rental_information\n                                                        .annual_escalation_percentage\n                                                "}})],1)],1),_c('b-row',{},[_c('b-col',[_c('b-row',[_c('b-col',[_c('b-form-checkbox',{staticClass:"mb-3",attrs:{"disabled":_vm.$apollo.loading &&
                                                            !_vm.create_mode},model:{value:(
                                                            _vm.form
                                                                .current_rental_information
                                                                .includes_electricity
                                                        ),callback:function ($$v) {_vm.$set(_vm.form
                                                                .current_rental_information
                                                                , "includes_electricity", $$v)},expression:"\n                                                            form\n                                                                .current_rental_information\n                                                                .includes_electricity\n                                                        "}},[_vm._v(" Includes Electricity ")])],1)],1),_c('b-row',[_c('b-col',[_c('b-form-checkbox',{staticClass:"mb-3",attrs:{"disabled":_vm.$apollo.loading &&
                                                            !_vm.create_mode},model:{value:(
                                                            _vm.form
                                                                .current_rental_information
                                                                .includes_water
                                                        ),callback:function ($$v) {_vm.$set(_vm.form
                                                                .current_rental_information
                                                                , "includes_water", $$v)},expression:"\n                                                            form\n                                                                .current_rental_information\n                                                                .includes_water\n                                                        "}},[_vm._v(" Includes Water ")])],1)],1),_c('b-row',[_c('b-col',[_c('b-form-checkbox',{staticClass:"mb-3",attrs:{"disabled":_vm.$apollo.loading &&
                                                            !_vm.create_mode},model:{value:(
                                                            _vm.form
                                                                .current_rental_information
                                                                .includes_wifi
                                                        ),callback:function ($$v) {_vm.$set(_vm.form
                                                                .current_rental_information
                                                                , "includes_wifi", $$v)},expression:"\n                                                            form\n                                                                .current_rental_information\n                                                                .includes_wifi\n                                                        "}},[_vm._v(" Includes WiFi ")])],1)],1)],1)],1)],1)])]}}],null,false,1682538478)})],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }