<template>
  <b-modal
    v-model="modal.show"
    size="lg"
    id="sign-contract-modal"
    :title="contract.name + ' Agreement'"
    ok-title="Agree"
    @hidden="handle_modal_hide"
    @ok="handle_ok"
    class="p-0"
  >
    <b-container>
      <b-col sm="12">
        <h1 class="text-center">
          <i v-if="$apollo.loading" class="fas fa-spinner fa-spin"></i>
        </h1>
      </b-col>
      <div
        id="contract-container"
        class="contract-container mb-5"
        v-html="contract.html_content"
      ></div>
      <hr class="mr-3" />

      <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
        <b-form @submit.prevent="handleSubmit(on_submit)">
          <input type="submit" ref="form_submit_button" hidden />
          <b-row class="mb-3">
            <b-col>
              <base-input
                :rules="{
                  required: {
                    allowFalse: false,
                  },
                }"
                name="Agree"
              >
                <b-form-checkbox
                  v-model="form.accepted"
                  @change="get_user_location()"
                >
                  I Agree to the "{{ contract.name }}" Agreement?
                </b-form-checkbox>
              </base-input>
              <small v-if="user_location.collecting_location"
                >We will ask for your location to keep a record of this
                agreement.
                <i
                  v-if="user_location.collecting_location"
                  class="fas fa-spinner fa-spin ml-1"
                ></i>
              </small>
            </b-col>
          </b-row>
          <b-row v-if="user_location.manual_location_required">
            <b-col lg="8">
              <base-input
                :rules="{ required: user_location.manual_location_required }"
                name="Address"
                class="mb-1"
              >
                <vue-google-autocomplete
                  id="map"
                  classname="form-control mb-1"
                  placeholder="Please type your address as you see it on google"
                  v-on:placechanged="getAddressData"
                  v-model="user_location.location_text"
                >
                </vue-google-autocomplete>
                <div
                  class="invalid-feedback ml-3"
                  style="display: block"
                  v-if="user_location.error_message"
                >
                  {{ user_location.error_message }}
                </div>
                <small>
                  We weren't able get your location. Please enter your address.
                </small>
              </base-input>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-container>

    <template #modal-footer="{ ok }">
      <b-button variant="default" @click="ok()" :disabled="modal.loading">
        <i v-if="modal.loading" class="fas fa-spinner fa-spin"></i>
        <i v-if="modal.success" class="fas fa-check"></i>
        Agree
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Vue from "vue";
import LoadScript from "vue-plugin-load-script";

// Compoonents
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import VueGoogleAutocomplete from "vue-google-autocomplete";

// Queries
import { GET_CONTRACT_CONTRACT } from "@/graphql/queries";

// Mutations
import { CREATE_CONTRACT_EXECUTION } from "@/graphql/mutations";
import { emit } from "process";
import { validate } from "graphql";

Vue.use(LoadScript);

// Perfect scrollbar functions
function has_element(className) {
  return document.getElementsByClassName(className).length > 0;
}

function init_scrollbar(className) {
  if (has_element(className)) {
    new PerfectScrollbar(`.${className}`, { suppressScrollX: true });
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      init_scrollbar(className);
    }, 100);
  }
}

export default {
  name: "SignContractModal",
  emits: ["contract_signed"],
  components: {
    VueGoogleAutocomplete,
  },
  props: {
    value: Boolean,
    contract_id: {
      type: String,
      description: "ID of the contract to sign",
      default: null,
    },
  },
  apollo: {
    get_contract: {
      query: GET_CONTRACT_CONTRACT,
      result(data) {
        this.handle_get_contract(data);
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors);
        return false;
      },
      update(data) {
        this.apollo_data.get_contract = data;
      },
      variables() {
        return {
          contract_id: this.contract_id,
        };
      },
      skip: true,
    },
  },
  data() {
    return {
      apollo_data: {
        get_contract: null,
      },
      modal: { show: this.value, loading: false, success: false },
      isSetupComplete: false,
      form: {
        accepted: false,

        // rating: { value: null, error: null },
        // headline: null,
        // comment: null,
      },
      contract: {
        name: null,
        html_content: null,
        contract_version_id: null,
      },

      user_location: {
        long: null,
        lat: null,
        location_text: null,
        collecting_location: false,
        collection_success: false,
      },
    };
  },
  methods: {
    getAddressData: function (addressData, placeResultData, id) {
      this.user_location.location_text = placeResultData.formatted_address;
      this.user_location.lat = addressData.latitude;
      this.user_location.long = addressData.longitude;
    },
    validateAddressData: function () {
      if (!this.user_location.lat || !this.user_location.long) {
        this.user_location.manual_location_required = true;

        return false;
      }
      this.user_location.error_message = null;
      return true;
    },
    on_submit() {
      this.modal.loading = true;
      if (this.form.accepted && this.validateAddressData()) {
        this.create_new_contract_exectuion();
      } else {
        this.modal.loading = false;
      }
    },
    // Scroll bar
    init_scrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        init_scrollbar("contract-container");
      }
    },

    // Geolocation
    get_user_location() {
      this.user_location.collecting_location = true;

      if ("geolocation" in navigator) {
        // Create a Promise that will reject in 10 seconds
        const locationTimeout = new Promise((_, reject) =>
          setTimeout(() => reject(new Error("Location fetch timed out")), 10000)
        );

        // Create a Promise for the geolocation
        const getPosition = new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject, {
            enableHighAccuracy: true,
            timeout: 20000,
          });
        });

        // Use Promise.race to handle whichever promise settles first
        Promise.race([locationTimeout, getPosition])
          .then((position) => {
            this.user_location.long = position.coords.longitude;
            this.user_location.lat = position.coords.latitude;
            this.user_location.collection_success = true;
            this.user_location.collecting_location = false;
            this.user_location.manual_location_required = false;
          })
          .catch((error) => {
            this.user_location.collecting_location = false;
            this.user_location.manual_location_required = true;
            console.log(error.message);
          });
      } else {
        this.user_location.manual_location_required = true;
      }
    },
    create_new_contract_exectuion() {
      this.$apollo
        .mutate({
          mutation: CREATE_CONTRACT_EXECUTION,
          variables: {
            contract_id: this.contract_id,
            user_id: store.getters.getUserIdB64,
            account_id: store.getters.getAccountIdB64,
            contract_version_id: this.contract.contract_version_id,
            location: this.user_location.location_text,
            gps_long: this.user_location.long,
            gps_lat: this.user_location.lat,
          },
        })
        .then((res) => {
          this.hide_modal();
          this.$emit("contract_signed");
        })

        .catch((res) => {
          this.modal.loading = false;
        });
    },

    // Apollo Handlers
    handle_get_contract(data) {
      let flattened = graph_utils.flatten_objects_recursive(
        graph_utils.apollo_to_obj_recursive(data.data)
      );
      this.contract.name = flattened.contractContract__name;
      this.contract.contract_version_id =
        flattened.contractContract__versions[0].id;
      this.contract.html_content =
        flattened.contractContract__versions[0].htmlContent;
    },
    // Apollo Triggers
    async enable_get_contract() {
      this.$apollo.queries.get_contract.setOptions({
        fetchPolicy: "network-only",
      });

      if (!this.$apollo.queries.get_contract.skip) {
        await this.$apollo.queries.get_contract.refetch();
      } else {
        this.$apollo.queries.get_contract.skip = false;
        await this.$apollo.queries.get_contract.start();
      }
    },
    async trigger_get_contract() {
      if (this.contract_id) {
        await this.enable_get_contract();
      }
    },
    // Modal Handlers
    handle_ok(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.$refs.form_submit_button.click();
    },

    handle_modal_hide() {
      this.$emit("input", false);
      setTimeout(() => {
        this.form.accepted = false;
      }, 10);
    },

    hide_modal() {
      this.modal.success = true;
      this.modal.loading = false;
      setTimeout(() => {
        this.modal.show = false;
        this.modal.success = false;
        this.$emit("input", false);
        this.modal.loading = false;
      }, 1000);
    },

    async setupModal() {
      try {
        await this.trigger_get_contract();
        this.init_scrollbar();
        this.isSetupComplete = true;
        this.modal.show = true;
      } catch (error) {
        console.error("Failed to set up modal:", error);
        this.$emit("input", false);
      }
    },
  },
  beforeCreate() {
    Vue.loadScript(
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCmKK8RSy2IysMH6exrjnokna_Bes1FPNU&libraries=places"
    );
  },
  watch: {
    value(new_value) {
      if (new_value && !this.isSetupComplete) {
        this.setupModal();
      } else {
        this.modal.show = new_value;
      }
    },

    contract_id() {
      this.trigger_get_contract();
    },
  },
};
</script>

<style>
#contract-container {
  /* background-color: rgb(255, 255, 255); */
  position: relative;
  /* margin: 10px auto; */
  /* padding: 0px; */
  height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.enter-active,
.leave-active {
  transition: opacity 0.15s;
}

.enter,
.leave-to {
  opacity: 0;
}

.pac-container {
  z-index: 1051 !important; /* Ensure google autocomplete suggestions is higher than the modal's z-index */
}
</style>
