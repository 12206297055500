var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Transition',{attrs:{"name":"fade"}},[_c('b-card',[_c('b-card-body',{staticClass:"p-0"},[_c('b-row',[_c('b-col',[_c('h4',{staticClass:"text-muted heading-small"},[_vm._v(" Approval Status ")])])],1),_c('b-row',[_c('b-col',[_c('badge',{attrs:{"rounded":true,"size":"md","type":_vm.state_badge_style_type(_vm.approval_status.name)}},[_vm._v(_vm._s(_vm.approval_status.friendly_name)+" ")])],1)],1),(
                        !['APPROVED', 'SUBMITTED'].includes(
                            _vm.approval_status.name
                        ) && !_vm.$apollo.loading
                    )?_c('b-row',[_c('b-col',[_c('hr')])],1):_vm._e(),_c('Transition',[(
                            !['APPROVED', 'SUBMITTED'].includes(
                                _vm.approval_status.name
                            )
                        )?_c('b-row',[_c('b-col',[_c('h4',{staticClass:"text-muted heading-small"},[_vm._v(" Submit for Approval ")])])],1):_vm._e()],1),_c('Transition',[(
                            !['APPROVED', 'SUBMITTED'].includes(
                                _vm.approval_status.name
                            ) && !_vm.$apollo.loading
                        )?_c('b-row',[_c('b-col',[_c('validation-observer',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var handleSubmit = ref.handleSubmit;
return [_c('b-form',{ref:"approval_status_form_submit",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.on_form_submit)}}},[_c('b-row',[_c('b-col',[_c('base-input',{attrs:{"label":"Message","rules":{ required: false },"type":"textarea","name":"Message","rows":"3","is_loading":_vm.$apollo.loading ||
                                                    _vm.form.is_loading},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}})],1)],1),_c('b-row',[_c('b-col',{staticClass:"text-center"},[_c('base-button',{staticClass:"mt-2",attrs:{"type":"primary","pill":true,"native-type":"submit","loading":_vm.form.is_loading,"success":_vm.form.save_button
                                                        .show_success,"disabled":_vm.form.is_loading}},[_vm._v("Submit for Approval ")])],1)],1)],1)]}}],null,false,3489981595)})],1)],1):_vm._e()],1),(_vm.approval_states_updates.length > 0)?_c('b-row',[_c('b-col',[_c('hr')])],1):_vm._e(),(_vm.approval_states_updates.length > 0)?_c('b-row',[_c('b-col',[_c('h4',{staticClass:"text-muted heading-small"},[_vm._v(" Approval History ")])])],1):_vm._e(),_c('b-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.approval_states_updates.length > 0),expression:"approval_states_updates.length > 0"}]},[_c('b-col',[_c('div',{staticClass:"approval-item-list",attrs:{"id":"approval-container"}},[_c('div',{staticClass:"content"},_vm._l((_vm.approval_states_updates),function(approval_state_update){return _c('approval-submitter-state-item',{key:approval_state_update.id,attrs:{"comment":approval_state_update.comment,"created_date_time":approval_state_update.created,"id":approval_state_update.id,"state_friendly_name":approval_state_update.newState__friendlyName,"state_name":approval_state_update.newState__name,"name":"approval"}})}),1)])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }