<template>
    <b-modal
        v-model="modal.show"
        size="md"
        id="rating-cru-modal"
        :title="rating_submission_id === null ? 'Create Rating' : 'Edit Rating'"
        ok-title="Save"
        @hidden="handle_modal_hide"
        @ok="handle_ok"
        class="p-0"
    >
        <b-container
            ><div v-if="rating_subject">
                <label class="form-control-label" for="">Who</label>
                <h2>{{ rating_subject }}</h2>
            </div>
            <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
                <b-form @submit.prevent="handleSubmit(on_submit)">
                    <input type="submit" ref="form_submit_button" hidden />
                    <b-row>
                        <b-col>
                            <label class="form-control-label" for=""
                                >Overall rating</label
                            >
                            <div class="has-label">
                                <rating-stars-comp
                                    v-model="form.rating.value"
                                    :rules="{ required: true }"
                                    name="Rating"
                                ></rating-stars-comp>
                            </div>
                            <div
                                v-if="form.rating.error"
                                class="invalid-feedback"
                                style="display: block"
                            >
                                {{ form.rating.error }}
                            </div>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <base-input
                                label="Headline"
                                class="mb-3"
                                placeholder="What is most important to know?"
                                name="Headline"
                                :rules="{ required: false }"
                                v-model="form.headline"
                                :disabled="$apollo.loading"
                            >
                            </base-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <base-input
                                label="Detail*"
                                class="mb-3"
                                placeholder="More information about your review, what did they do well? What didn't they do well?"
                                name="Description"
                                :rules="{
                                    required: true,
                                    min: 50,
                                }"
                                type="textarea"
                                rows="5"
                                v-model="form.comment"
                            >
                            </base-input>
                        </b-col>
                    </b-row>
                </b-form> </validation-observer
        ></b-container>

        <template #modal-footer="{ ok }">
            <b-button
                variant="default"
                @click="ok()"
                :disabled="modal.loading || $apollo.loading"
            >
                <i
                    v-if="modal.loading || $apollo.loading"
                    class="fas fa-spinner fa-spin"
                ></i>
                <i v-if="modal.success" class="fas fa-check"></i>
                Save
            </b-button>
        </template>
    </b-modal>
</template>

<script>
// Compoonents
import RatingStarsComp from "@/views/Components/Rating/RatingStarsComp.vue";

// Queries
import { GET_RATING_SUBMISSION } from "@/graphql/queries";

// Mutations
import { CREATE_RATING_RATING_SUBMISSION } from "@/graphql/mutations";
import { UPDATE_RATING_RATING_SUBMISSION } from "@/graphql/mutations";

export default {
    name: "CrudRadingModal",
    emits: ["created"],
    components: { RatingStarsComp },
    props: {
        value: Boolean,
        rating_submission_id: {
            type: String,
            description: "ID of the rating submission, can be none.",
            default: null,
        },
        rating_id: {
            type: String,
            description: "ID of the parent rating, can be none.",
            default: null,
        },
        rating_subject: {
            type: String,
            description: "The name of the subject to be rated",
            default: null,
        },
    },
    apollo: {
        get_rating_submission: {
            query: GET_RATING_SUBMISSION,
            result(data) {
                this.handle_get_rating_submission(data);
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors);
                return false;
            },
            update(data) {
                this.apollo_data.get_rating_submission = data;
            },
            variables() {
                return {
                    rating_submission_id: this.rating_submission_id,
                };
            },
            skip: true,
        },
    },
    data() {
        return {
            apollo_data: {
                get_rating_submission: null,
            },
            modal: { show: this.value, loading: false, success: false },
            form: {
                rating: { value: null, error: null },
                headline: null,
                comment: null,
            },
        };
    },
    methods: {
        // Form Hanlders
        on_submit() {
            this.modal.loading = true;

            if (this.rating_submission_id) {
                this.update_rating_submission();
            } else {
                this.create_rating_submission();
            }
        },

        create_rating_submission() {
            this.$apollo
                .mutate({
                    mutation: CREATE_RATING_RATING_SUBMISSION,
                    variables: {
                        rating_id: this.rating_id,
                        comment: this.form.comment,
                        headline: this.form.headline,
                        rating_stars: this.form.rating.value,
                        user_id: store.getters.getUserIdB64,
                    },
                })
                .then((res) => {
                    this.hide_modal();
                    this.$emit("created");
                    global_event_emitter.$emit(
                        "service_provider_rating_create"
                    );
                })
                .finally((res) => {
                    this.modal.loading = false;
                });
        },
        update_rating_submission() {
            this.$apollo
                .mutate({
                    mutation: UPDATE_RATING_RATING_SUBMISSION,
                    variables: {
                        rating_submission_id: this.rating_submission_id,
                        comment: this.form.comment,
                        headline: this.form.headline,
                        rating_stars: this.form.rating.value,
                    },
                })
                .then((res) => {
                    this.hide_modal();
                })
                .finally((res) => {
                    this.modal.loading = false;
                });
        },

        // Apollo Handlers
        handle_get_rating_submission(data) {
            this.form.headline = data.data.ratingRatingSubmission.headline;
            this.form.comment = data.data.ratingRatingSubmission.comment;
            this.form.rating.value = Number(
                data.data.ratingRatingSubmission.stars
            );
        },
        // Apollo Triggers
        enable_get_rating_submission() {
            this.$apollo.queries.get_rating_submission.setOptions({
                fetchPolicy: "network-only",
            });

            if (!this.$apollo.queries.get_rating_submission.skip) {
                this.$apollo.queries.get_rating_submission.refetch();
            } else {
                this.$apollo.queries.get_rating_submission.skip = false;
                this.$apollo.queries.get_rating_submission.start();
            }
        },
        trigger_get_rating_submission() {
            if (this.rating_submission_id) {
                this.enable_get_rating_submission();
            }
        },
        // Modal Handlers
        handle_ok(bvModalEvent) {
            bvModalEvent.preventDefault();

            if (!this.$apollo.loading) {
                if (this.form.rating.value == null) {
                    this.form.rating.error = "Please provide a rating";
                } else {
                    this.$refs.form_submit_button.click();
                }
            }
        },
        handle_modal_hide() {
            this.$emit("input", false);
            setTimeout(() => {
                this.form = {
                    rating: { value: null, error: null },
                    headline: null,
                    detail: null,
                };
            }, 10);
        },

        hide_modal() {
            this.modal.success = true;
            this.modal.loading = false;
            setTimeout(() => {
                this.modal.show = false;
                this.modal.success = false;
                this.$emit("input", false);
                this.modal.loading = false;
            }, 1000);
        },
    },
    mounted() {
        this.trigger_get_rating_submission();
    },
    watch: {
        value(new_value) {
            this.modal.show = new_value;
            this.trigger_get_rating_submission();
        },
        "form.rating.value"() {
            if (this.form.rating.value > 0) {
                this.form.rating.error = "";
            }
            // else {
            //     this.form.rating.value = null;
            // }
        },
        rating_submission_id() {
            this.trigger_get_rating_submission();
        },
    },
};
</script>

<style></style>
