<template>
  <b-modal
    v-model="modal.show"
    size="lg"
    id="sign-contract-modal"
    :title="contract.name + ' Agreement'"
    class="p-0"
    @hidden="hide_modal"
    hide-footer
  >
    <b-container>
      <div
        id="contract-container"
        class="contract-container mb-5"
        v-html="contract.html_content"
      ></div>
    </b-container>
  </b-modal>
</template>

<script>
// Compoonents
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// Queries
import { GET_CONTRACT_CONTRACT } from "@/graphql/queries";

// Perfect scrollbar functions
function has_element(className) {
  return document.getElementsByClassName(className).length > 0;
}

function init_scrollbar(className) {
  if (has_element(className)) {
    new PerfectScrollbar(`.${className}`, { suppressScrollX: true });
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      init_scrollbar(className);
    }, 100);
  }
}

export default {
  name: "SignContractModal",
  emits: ["contract_signed"],
  components: {},
  props: {
    value: Boolean,
    contract_id: {
      type: String,
      description: "ID of the contract to sign",
      default: null,
    },
  },
  apollo: {
    get_contract: {
      query: GET_CONTRACT_CONTRACT,
      result(data) {
        this.handle_get_contract(data);
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors);
        return false;
      },
      update(data) {
        this.apollo_data.get_contract = data;
      },
      variables() {
        return {
          contract_id: this.contract_id,
        };
      },
      skip: true,
    },
  },
  data() {
    return {
      apollo_data: {
        get_contract: null,
      },
      modal: { show: this.value, loading: false, success: false },
      isSetupComplete: false,
      contract: {
        name: null,
        html_content: null,
        contract_version_id: null,
      },
    };
  },
  methods: {
    // Scroll bar
    init_scrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        init_scrollbar("contract-container");
      }
    },
    // Apollo Handlers
    handle_get_contract(data) {
      let flattened = graph_utils.flatten_objects_recursive(
        graph_utils.apollo_to_obj_recursive(data.data)
      );
      this.contract.name = flattened.contractContract__name;
      this.contract.contract_version_id =
        flattened.contractContract__versions[0].id;
      this.contract.html_content =
        flattened.contractContract__versions[0].htmlContent;
    },
    // Apollo Triggers
    async enable_get_contract() {
      this.$apollo.queries.get_contract.setOptions({
        fetchPolicy: "network-only",
      });

      if (!this.$apollo.queries.get_contract.skip) {
        await this.$apollo.queries.get_contract.refetch();
      } else {
        this.$apollo.queries.get_contract.skip = false;
        await this.$apollo.queries.get_contract.start();
      }
    },
    async trigger_get_contract() {
      if (this.contract_id) {
        await this.enable_get_contract();
      }
    },
    // Modal Handlers
    hide_modal() {
      this.modal.loading = false;
      setTimeout(() => {
        this.modal.show = false;
        this.$emit("input", false);
        this.modal.loading = false;
      }, 1000);
    },
    async setupModal() {
      try {
        await this.trigger_get_contract();
        this.init_scrollbar();
        this.isSetupComplete = true;
        this.modal.show = true;
      } catch (error) {
        console.error("Failed to set up modal:", error);
        this.$emit("input", false);
      }
    },
  },
  watch: {
    value(new_value) {
      if (new_value && !this.isSetupComplete) {
        this.setupModal();
      } else {
        this.modal.show = new_value;
      }
    },

    contract_id() {
      this.trigger_get_contract();
    },
  },
};
</script>

<style scoped>
#contract-container {
  /* background-color: rgb(255, 255, 255); */
  position: relative;
  /* margin: 10px auto; */
  /* padding: 0px; */
  height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
