/
<template>
    <div>
        <b-row>
            <b-col sm="12" md="3">
                <badge
                    class="mr-1"
                    :rounded="true"
                    size="md"
                    :type="get_status_type(listing_status.name)"
                    v-if="listing_status.id"
                    >{{ listing_status.friendly_name }}</badge
                >
                <base-button
                    type="none"
                    size="sm"
                    :pill="true"
                    @click="toggle_edit_mode()"
                >
                    <i class="fas fa-edit text-orange"></i>
                </base-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="12" md="4">
                <validation-observer
                    v-slot="{ handleSubmit }"
                    ref="formValidator"
                >
                    <b-form
                        @submit.prevent="handleSubmit(on_form_submit)"
                        ref="status_form_submit"
                    >
                        <input
                            type="submit"
                            ref="status_form_submit_button"
                            hidden
                        />
                        <Transition name="fade">
                            <base-input
                                name="Listing Status"
                                label="Update Listing Status"
                                :rules="{ required: true }"
                                :is_loading="form.loading"
                                v-if="form.show"
                            >
                                <el-select
                                    v-model="form.status.selection"
                                    filterable
                                    placeholder="Select"
                                    :disabled="form.loading"
                                    @change="submit_form()"
                                >
                                    <el-option
                                        v-for="option in form.status.options"
                                        :key="option.id"
                                        :label="option.friendly_name"
                                        :value="option.id"
                                    >
                                    </el-option>
                                </el-select>
                                <div
                                    class="invalid-feedback"
                                    style="display: block"
                                    v-if="form.status.error_message"
                                >
                                    {{ form.status.error_message }}
                                </div>
                            </base-input>
                        </Transition>
                    </b-form>
                </validation-observer>
            </b-col>
        </b-row>
    </div>
</template>

<script>
// Modules
import { Select, Option } from "element-ui";

// Queries
import { GET_ALL_PROPERTY_LISTING_SATUSES } from "@/graphql/queries";
import { GET_PROPERTY_LISTING_SATUS } from "@/graphql/queries";

// Mutations
import { UPDATE_PROPERTY_LISTING_STATUS } from "@/graphql/mutations";

export default {
    name: "UpdateListingStatusComp",
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
    },
    emits: ["listing_status_updated"],
    props: {
        listing_id: {
            type: String,
            description: "Listing ID",
            default: null,
        },
    },
    apollo: {
        get_property_listing_status: {
            query: GET_PROPERTY_LISTING_SATUS,
            result(data) {
                this.handle_get_property_listing_status(data);
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors);
                return false;
            },
            update(data) {
                this.apollo_data.get_property_listing_status = data;
            },
            variables() {
                return {
                    listing_id: this.listing_id,
                };
            },
            skip: true,
        },
        get_status_options: {
            query: GET_ALL_PROPERTY_LISTING_SATUSES,
            result(data) {
                this.handle_get_status_options(data);
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors);
                return false;
            },
            update(data) {
                this.apollo_data.get_status_options = data;
            },
        },
    },
    data() {
        return {
            apollo_data: {
                get_property_listing_status: null,
                get_status_options: null,
            },
            listing_status: {
                id: null,
                friendly_name: null,
                name: null,
            },
            form: {
                loading: false,
                show: false,
                status: {
                    selection: null,
                    options: [
                        {
                            name: null,
                            id: null,
                            friendly_name: null,
                        },
                    ],
                },
            },
        };
    },
    methods: {
        // Form event handlers
        submit_form() {
            this.$refs.status_form_submit_button.click();
        },
        on_form_submit() {
            this.form.loading = true;
            this.$apollo
                .mutate({
                    mutation: UPDATE_PROPERTY_LISTING_STATUS,
                    variables: {
                        listing_id: this.listing_id,
                        listing_status_id: this.form.status.selection,
                    },
                })
                .then((res) => {
                    this.form.loading = false;
                    global_event_emitter.$emit("listing_status_update");
                    setTimeout(() => {
                        this.form.show = false;
                    }, 1000);
                })
                .catch((res) => {
                    this.form.loading = false;
                });
        },

        // Apollo handlers
        handle_get_property_listing_status(data) {
            this.listing_status = {
                id: data.data.propertyListing.status.id,
                name: data.data.propertyListing.status.name,
                friendly_name: data.data.propertyListing.status.friendlyName,
            };
            this.form.status.selection = data.data.propertyListing.status.id;
        },

        handle_get_status_options(data) {
            let allowed_statuses = [
                "IN_REVIEW",
                "SOLD",
                "UNDER_OFFER",
                "PUBLISHED",
                "NOT_AVAILABLE",
            ];

            this.form.status.options = [];

            let flattened = graph_utils.flatten_objects_recursive(
                graph_utils.apollo_to_obj_recursive(data.data)
            );

            for (
                let i = 0;
                i < flattened.allPropertyListingStatus.length;
                i++
            ) {
                if (
                    allowed_statuses.includes(
                        flattened.allPropertyListingStatus[i].name
                    )
                ) {
                    this.form.status.options.push({
                        id: flattened.allPropertyListingStatus[i].id,
                        name: flattened.allPropertyListingStatus[i].name,
                        friendly_name:
                            flattened.allPropertyListingStatus[i].friendlyName,
                    });
                }
            }
        },

        // Apollo managers
        enable_get_property_listing_status() {
            if (this.listing_id) {
                if (!this.$apollo.queries.get_property_listing_status.skip) {
                    this.$apollo.queries.get_property_listing_status.refetch();
                } else {
                    this.$apollo.queries.get_property_listing_status.skip = false;
                    this.$apollo.queries.get_property_listing_status.start();
                    this.$apollo.queries.get_property_listing_status.setOptions(
                        {
                            fetchPolicy: "network-only",
                        }
                    );
                }
            } else {
                if (this.$apollo.queries.get_property_listing_status.skip) {
                    this.$apollo.queries.get_property_listing_status.skip = true;
                }
            }
        },

        // Style methods
        get_status_type(status) {
            if (["SOLD", "PUBLISHED", "UNDER_OFFER"].includes(status)) {
                return "success";
            } else if (["CREATED", "IN_REVIEW"].includes(status)) {
                return "warning";
            } else {
                return "danger";
            }
        },

        toggle_edit_mode() {
            this.form.show = !this.form.show;
        },
    },

    mounted() {
        this.enable_get_property_listing_status();
    },
    created() {
        global_event_emitter.$on("listing_status_update", () => {
            setTimeout(() => {
                this.enable_get_property_listing_status();
            }, 1000);
        });
    },
    beforeDestroy() {
        global_event_emitter.$off("listing_status_update");
    },
    watch: {
        listing_id() {
            enable_get_property_listing_status();
        },
    },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
