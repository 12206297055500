<template>
    <div>
        <b-row class="m-2 justify-content-start">
            <b-col
                class="m-2 p-0"
                cols="auto"
               
                v-for="image in available_images"
                :key="image.id"
            >
                <div class="img-wrap">
                    <span class="close" @click="delete_image(image.id)"
                        >&times;</span
                    >
                    <b-img
                        :src="image.file"
                        rounded
                        alt="Rounded image"
                        width="100px"
                    >
                    </b-img>
                </div>
            </b-col>
        </b-row>
        <b-row class="pt-2">
            <b-col>
                <drop-images
                    @changed="handle_images"
                    :max="30"
                    maxError="Max files exceed"
                    fileError="images files only accepted"
                    :clearAllAction="clear_all"
                />
                <i v-if="loading" class="fas fa-spinner fa-spin"></i>
            </b-col>
        </b-row>

        <!-- <UploadImages
            @changed="handleImages"
            :max="5"
            maxError="Max files exceed"
            fileError="images files only accepted"
        /> -->
        <!-- <drop-images
            @changed="handle_images"
            :max="5"
            maxError="Max files exceed"
            fileError="images files only accepted"
            :clearAllAction="clear_all"
        /> -->
    </div>
</template>
<script>
// Modules
import DropImages from "@/views/Components/DropImages.vue";

// Queries
import { GET_PROPERTY_LISTING_ARTIFACTS } from "@/graphql/queries";

// Mutations
import { DELETE_PROPERTY_ARTIFACT } from "@/graphql/mutations";

export default {
    name: "listing-photo-manager",
    components: {
        DropImages,
    },
    apollo: {
        get_property_listing_artifacts: {
            query: GET_PROPERTY_LISTING_ARTIFACTS,
            result(data) {
                this.handle_get_property_listing_artifacts(data);
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors);
                return false;
            },
            update(data) {
                this.apollo_data.get_property_listing_artifacts = data;
            },
            variables() {
                return {
                    listing_id: this.listing_id,
                };
            },
        },
    },
    props: {
        listing_id: {
            type: String,
            description: "ID",
            default: "",
        },
    },
    data() {
        return {
            files: [],
            added_files: [],
            removed_files: [],
            listing_id_numeric: "",
            clear_all: false,
            apollo_data: {},
            available_images: [],
            loading: false,
        };
    },
    methods: {
        // Apollo Handlers
        handle_get_property_listing_artifacts(data) {
            if ("data" in data) {
                let flattened = graph_utils.flatten_objects_recursive(
                    graph_utils.apollo_to_obj_recursive(data.data)
                );
                this.available_images = flattened.allPropertyArtifact;
            }
        },
        // Event Handlers
        handle_images(files) {
            let previous_files = structuredClone(this.files);
            this.get_added_files(previous_files, files)
                .then((res) => {
                    this.added_files = res;
                })
                .then(() => {
                    this.get_removed_files(previous_files, files)
                        .then((res) => {
                            this.removed_files = res;
                        })
                        .then(() => {
                            this.files = structuredClone(files);
                            this.upload_files(this.added_files).then(() => {
                                this.reset_drop_images();
                            });
                        });
                });
        },

        delete_image(id) {
            let variables = {
                artifact_id: id,
            };

            this.$apollo
                .mutate({
                    mutation: DELETE_PROPERTY_ARTIFACT,
                    variables: variables,
                })
                .then((res) => {
                    this.reload_listing_artifacts();
                });
        },

        // Utils
        async get_added_files(old_files, new_files) {
            let added_files = [];

            if (new_files.length > 0) {
                if (old_files.length < 1) {
                    return new_files;
                }
                let found = false;

                for (let i = 0; i < new_files.length; i++) {
                    found = false;
                    for (let ii = 0; ii < old_files.length; ii++) {
                        if (new_files[i].name === old_files[ii].name) {
                            found = true;
                        }
                    }
                    if (!found) {
                        added_files.push(new_files[i]);
                    }
                }
            } else {
                return [];
            }
            return added_files;
        },
        async get_removed_files(old_files, new_files) {
            let removed_files = [];

            if (new_files.length < 1) {
                return old_files;
            } else {
                let found = false;

                for (let i = 0; i < old_files.length; i++) {
                    found = false;
                    for (let ii = 0; ii < new_files.length; ii++) {
                        if (old_files[i].name === new_files[ii].name) {
                            found = true;
                        }
                    }
                    if (!found) {
                        removed_files.push(old_files[i]);
                    }
                }
            }
            return removed_files;
        },

        reset_drop_images() {
            this.clear_all = true;
            setTimeout(() => {
                this.clear_all = false;
            }, 50);
        },

        reload_listing_artifacts() {
            this.$apollo.queries.get_property_listing_artifacts.refetch();
        },

        // REST API Uploads

        async upload_files(files) {
            if (files.length > 0) {
                files.forEach((element) => {
                    this.upload_file(element);
                });
            }
        },
        upload_file(file) {
            this.loading = true;
            rest_api.property
                .upload_listing_image(file, this.listing_id_numeric)
                .then(() => {
                    this.loading = false;
                    this.reload_listing_artifacts();
                });
        },

        update_listing_id_numeric() {
            let relay_id = Buffer.from(this.listing_id, "base64").toString(
                "utf8"
            );
            let split_relay_b64_id = relay_id.split(":");

            this.listing_id_numeric = Number(
                split_relay_b64_id[split_relay_b64_id.length - 1]
            );
        },
    },
    watch: {
        listing_id() {
            this.update_listing_id_numeric();
        },
    },
    mounted() {
        this.update_listing_id_numeric();
    },
};
</script>
<style>
.img-wrap {
    position: relative;
    display: inline-block;
    font-size: 0;
}
.img-wrap .close {
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: 100;
    background-color: #f14900;
    padding: 4px 4px 2px;
    width: 20px;
    height: 20px;
    color: #000;
    font-weight: bold;
    cursor: pointer;
    opacity: 0.6;
    text-align: center middle;
    font-size: 22px;
    line-height: 10px;
    border-radius: 50%;
}
.img-wrap .close:hover {
    opacity: 1;
    background-color: #120166;
    color: #fff;
}
</style>
