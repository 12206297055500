<template>
    <div>
        <Transition name="fade">
            <b-card>
                <b-card-body class="p-0">
                    <b-row>
                        <b-col>
                            <h4 class="text-muted heading-small">
                                Approval Status
                            </h4>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <badge
                                :rounded="true"
                                size="md"
                                :type="
                                    state_badge_style_type(approval_status.name)
                                "
                                >{{ approval_status.friendly_name }}
                            </badge>
                        </b-col>
                    </b-row>
                    <b-row
                        v-if="
                            !['APPROVED', 'SUBMITTED'].includes(
                                approval_status.name
                            ) && !$apollo.loading
                        "
                    >
                        <b-col>
                            <hr />
                        </b-col>
                    </b-row>
                    <Transition>
                        <b-row
                            v-if="
                                !['APPROVED', 'SUBMITTED'].includes(
                                    approval_status.name
                                )
                            "
                        >
                            <b-col>
                                <h4 class="text-muted heading-small">
                                    Submit for Approval
                                </h4>
                            </b-col>
                        </b-row>
                    </Transition>
                    <Transition>
                        <b-row
                            v-if="
                                !['APPROVED', 'SUBMITTED'].includes(
                                    approval_status.name
                                ) && !$apollo.loading
                            "
                        >
                            <b-col>
                                <validation-observer
                                    v-slot="{ handleSubmit }"
                                    ref="formValidator"
                                >
                                    <b-form
                                        @submit.prevent="
                                            handleSubmit(on_form_submit)
                                        "
                                        ref="approval_status_form_submit"
                                    >
                                        <b-row>
                                            <b-col>
                                                <!-- <base-input
                                            label="Approval Status"
                                            :error="form.status.error_message"
                                            name="Approval Status"
                                            :rules="{ required: true }"
                                            :is_loading="
                                                $apollo.loading ||
                                                form.is_loading
                                            "
                                        >
                                            <el-select
                                                v-model="form.status.selection"
                                                filterable
                                                placeholder="Select"
                                                :disabled="
                                                    $apollo.loading ||
                                                    form.is_loading
                                                "
                                            >
                                                <el-option
                                                    v-for="option in form.status
                                                        .options"
                                                    :key="option.label"
                                                    :label="option.label"
                                                    :value="option.value"
                                                >
                                                </el-option>
                                            </el-select>
                                            <div
                                                class="invalid-feedback"
                                                style="display: block"
                                                v-if="form.status.error_message"
                                            >
                                                {{ form.status.error_message }}
                                            </div>
                                        </base-input> -->
                                                <base-input
                                                    label="Message"
                                                    :rules="{ required: false }"
                                                    type="textarea"
                                                    name="Message"
                                                    rows="3"
                                                    v-model="form.message"
                                                    :is_loading="
                                                        $apollo.loading ||
                                                        form.is_loading
                                                    "
                                                >
                                                </base-input>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col class="text-center">
                                                <base-button
                                                    type="primary"
                                                    :pill="true"
                                                    native-type="submit"
                                                    class="mt-2"
                                                    :loading="form.is_loading"
                                                    :success="
                                                        form.save_button
                                                            .show_success
                                                    "
                                                    :disabled="form.is_loading"
                                                    >Submit for Approval
                                                </base-button>
                                            </b-col>
                                        </b-row>
                                    </b-form>
                                </validation-observer>
                            </b-col>
                        </b-row>
                    </Transition>
                    <b-row v-if="approval_states_updates.length > 0">
                        <b-col>
                            <hr />
                        </b-col>
                    </b-row>
                    <b-row v-if="approval_states_updates.length > 0">
                        <b-col>
                            <h4 class="text-muted heading-small">
                                Approval History
                            </h4>
                        </b-col>
                    </b-row>
                    <b-row v-show="approval_states_updates.length > 0">
                        <b-col>
                            <div
                                class="approval-item-list"
                                id="approval-container"
                            >
                                <div class="content">
                                    <approval-submitter-state-item
                                        v-for="approval_state_update in approval_states_updates"
                                        :key="approval_state_update.id"
                                        :comment="approval_state_update.comment"
                                        :created_date_time="
                                            approval_state_update.created
                                        "
                                        :id="approval_state_update.id"
                                        :state_friendly_name="
                                            approval_state_update.newState__friendlyName
                                        "
                                        :state_name="
                                            approval_state_update.newState__name
                                        "
                                        name="approval"
                                    >
                                    </approval-submitter-state-item>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </Transition>
    </div>
</template>

<script>
// Components
import { Select, Option } from "element-ui";
import ApprovalSubmitterStateItem from "@/views/Components/Approval/ApprovalSubmitterStateItem.vue";

import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// Queries
import { GET_APPROVAL_APPROVAL_DETAIL } from "@/graphql/queries";

// Mutations
import { CREATE_APPROVAL_STATE_UPDATE } from "@/graphql/mutations";

function has_element(className) {
    return document.getElementsByClassName(className).length > 0;
}

function init_scrollbar(className) {
    if (has_element(className)) {
        new PerfectScrollbar(`.${className}`, { suppressScrollX: true });
    } else {
        // try to init it later in case this component is loaded async
        setTimeout(() => {
            init_scrollbar(className);
        }, 100);
    }
}

export default {
    name: "ApprovalComponent",
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
        ApprovalSubmitterStateItem,
    },
    props: {
        approval_id: {
            type: String,
            description: "ID of the approval in question",
            default: null,
        },
        show_history: {
            type: Boolean,
            description: "Weather to show the historic states",
            default: false,
        },
    },
    apollo: {
        get_approval_detail: {
            query: GET_APPROVAL_APPROVAL_DETAIL,
            result(data) {
                this.handle_get_approval_detail(data);
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors);
                return false;
            },
            update(data) {
                this.apollo_data.get_approval_detail = data;
            },
            variables() {
                return {
                    approval_id: this.approval_id,
                };
            },
            skip: true,
        },
    },
    data() {
        return {
            show: true,
            apollo_data: {
                get_approval_detail: null,
            },
            approval_states: [],
            submitted_for_approval_state: {
                name: "",
                friendly_name: "",
                id: "",
            },
            form: {
                status: {
                    selection: "",
                    previous_selection: "",
                    options: [
                        {
                            value: "Test",
                            label: "Test",
                        },
                    ],
                    error_message: "",
                },
                message: null,
                save_button: {
                    show_success: false,
                },
                is_loading: false,
            },
            approval_status: {
                name: "",
                friendly_name: "",
                id: "",
            },
            approval_states_updates: [],
        };
    },
    methods: {
        // Form Event Handlers
        on_form_submit() {
            if (
                this.approval_status.id !== this.submitted_for_approval_state.id
            ) {
                this.create_approval_state_update();
            }
        },

        create_approval_state_update() {
            this.$apollo
                .mutate({
                    mutation: CREATE_APPROVAL_STATE_UPDATE,
                    variables: {
                        approval_id: this.approval_id,
                        new_state_id: this.submitted_for_approval_state.id,
                        comment: this.form.message,
                        created_by_user_id: this.$store.getters.getUserIdB64,
                    },
                })
                .then((res) => {
                    if (
                        "data" in res &&
                        "approvalCreateStateUpdate" in res.data
                    ) {
                        this.$apollo.queries.get_approval_detail.refetch();
                        this.form.save_button.show_success = true;
                        setTimeout(() => {
                            this.form.save_button.show_success = false;
                        }, 2000);
                    }
                });
        },

        //Apollo Handlers
        handle_get_approval_detail(data) {
            let flattened = graph_utils.flatten_objects_recursive(
                graph_utils.apollo_to_obj_recursive(data.data)
            );

            if (
                "approvalApproval__itemType__supportedStates" in flattened &&
                flattened.approvalApproval__itemType__supportedStates.length > 0
            ) {
                this.approval_states = [];
                flattened.approvalApproval__itemType__supportedStates.forEach(
                    (element) => {
                        this.approval_states.push({
                            id: element.id,
                            friendly_name: element.friendlyName,
                            name: element.name,
                        });
                        if (element.name === "SUBMITTED") {
                            this.submitted_for_approval_state = {
                                id: element.id,
                                friendly_name: element.friendlyName,
                                name: element.name,
                            };
                        }
                    }
                );
            }
            if ("approvalApproval__state__id" in flattened) {
                this.approval_status = {
                    name: flattened.approvalApproval__state__name,
                    friendly_name:
                        flattened.approvalApproval__state__friendlyName,
                    id: flattened.approvalApproval__state__id,
                };
            }

            if ("approvalApproval__stateUpdates" in flattened) {
                this.approval_states_updates =
                    flattened.approvalApproval__stateUpdates.reverse();
            }
        },

        // Apollo manager
        manage_get_approval_detail() {
            this.$apollo.queries.get_approval_detail.setOptions({
                fetchPolicy: "network-only",
            });

            if (this.approval_id) {
                if (!this.$apollo.queries.get_approval_detail.skip) {
                    this.$apollo.queries.get_approval_detail.refetch();
                } else {
                    this.$apollo.queries.get_approval_detail.skip = false;
                    // this.$apollo.queries.get_approval_detail.start();
                }
            } else {
                if (this.$apollo.queries.get_approval_detail.skip) {
                    this.$apollo.queries.get_approval_detail.skip = true;
                }
            }
        },

        state_badge_style_type(name) {
            if (name == "PENDING_SUBMISSION") {
                return "info";
            }
            if (name == "NEEDS_REVIEW") {
                return "warning";
            }
            if (name == "APPROVED") {
                return "success";
            }
            if (name == "SUBMITTED") {
                return "info";
            }
            return "danger";
        },

        // Scroll bar
        init_scrollbar() {
            let isWindows = navigator.platform.startsWith("Win");
            if (isWindows) {
                init_scrollbar("approval-item-list");
            }
        },

        get_max_height_approval_history_container() {
            let approval_content_height = 0;
            let approval_elements = document.getElementsByName("approval");
            for (let i = 0; i < approval_elements.length; i++) {
                approval_content_height += approval_elements[i].clientHeight;
            }
            if (approval_content_height < 750) {
                document.getElementById("approval-container").style.height =
                    approval_content_height + "px";
            } else {
                document.getElementById("approval-container").style.height =
                    "750px";
            }
        },
    },
    watch: {
        approval_id() {
            this.manage_get_approval_detail();
        },
        approval_states_updates() {
            setTimeout(() => {
                this.get_max_height_approval_history_container();
            }, 200);
        },
    },
    mounted() {
        this.manage_get_approval_detail();
        this.init_scrollbar();
    },
};
</script>

<style>
.el-input__inner {
    border-radius: 10rem;
}

#approval-container {
    /* background-color: rgb(255, 255, 255); */
    position: relative;
    /* margin: 10px auto; */
    /* padding: 0px; */
    height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
}

/* #approval-container .content {
    height: 900px;
} */

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
