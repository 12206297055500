var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('confirmation-modal',{attrs:{"confirmation_message":_vm.modals.confirmation_delete_all_rooms_modal.confirmation_message,"confirmation_sub_message":_vm.modals.confirmation_delete_all_rooms_modal
                .confirmation_sub_message},on:{"confirmed":_vm.handle_multi_let_to_outright_change},model:{value:(_vm.modals.confirmation_delete_all_rooms_modal.show),callback:function ($$v) {_vm.$set(_vm.modals.confirmation_delete_all_rooms_modal, "show", $$v)},expression:"modals.confirmation_delete_all_rooms_modal.show"}}),_c('b-card',[_c('b-card-body',{staticClass:"p-0"},[_c('validation-observer',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var handleSubmit = ref.handleSubmit;
return [_c('b-form',{ref:"future_rental_plan_form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.on_form_submit)}}},[_c('h6',{staticClass:"heading-small text-muted mb-4"},[_vm._v(" Future Rental Plan ")]),_c('div',{staticClass:"pl-lg-4"},[_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-checkbox',{staticClass:"mb-3",on:{"change":_vm.filter_rental_approach_options},model:{value:(_vm.form.allows_subletting),callback:function ($$v) {_vm.$set(_vm.form, "allows_subletting", $$v)},expression:"form.allows_subletting"}},[_vm._v(" Allows subletting? ")])],1)],1),_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-checkbox',{staticClass:"mb-3",model:{value:(_vm.form.max_occupants.specified),callback:function ($$v) {_vm.$set(_vm.form.max_occupants, "specified", $$v)},expression:"form.max_occupants.specified"}},[_vm._v(" Max occupants specified? ")])],1)],1),_c('Transition',[(_vm.form.max_occupants.specified)?_c('b-row',[_c('b-col',{attrs:{"lg":"4"}},[_c('base-input',{staticClass:"mb-3",attrs:{"label":"Maximum occupants allowed*","type":"number","placeholder":"How many occupants are allowed? ","name":"Max Occupants","rules":{
                                            required:
                                                _vm.form.max_occupants
                                                    .specified,
                                        }},on:{"keyup":_vm.debounced_on_form_submit},model:{value:(_vm.form.max_occupants.number),callback:function ($$v) {_vm.$set(_vm.form.max_occupants, "number", $$v)},expression:"form.max_occupants.number"}})],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"lg":"4"}},[_c('base-input',{attrs:{"label":"Future Rental Strategy","error":_vm.form.rental_approach.error_message,"name":"Future Rental Strategy","rules":{ required: true }}},[_c('el-select',{attrs:{"filterable":"","placeholder":"Select"},on:{"change":function($event){return _vm.handle_change_rental_strategy()}},model:{value:(
                                            _vm.form.rental_approach.selection
                                        ),callback:function ($$v) {_vm.$set(_vm.form.rental_approach, "selection", $$v)},expression:"\n                                            form.rental_approach.selection\n                                        "}},_vm._l((_vm.form
                                                .rental_approach.options),function(option){return _c('el-option',{key:option.label,attrs:{"label":option.label,"value":option.value}})}),1),(
                                            _vm.form.rental_approach
                                                .error_message
                                        )?_c('div',{staticClass:"invalid-feedback",staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(_vm.form.rental_approach .error_message)+" ")]):_vm._e()],1)],1),(_vm.form.months_of_year_occupied.show)?_c('b-col',{attrs:{"lg":"4"}},[_c('base-input',{staticClass:"mb-3",attrs:{"label":"Months of the year occupied","type":"number","placeholder":"How many months is it rented out?","name":"Months occupied","rules":{
                                        max_value: 12,
                                    }},on:{"keyup":_vm.debounced_on_form_submit},model:{value:(
                                        _vm.form.months_of_year_occupied.value
                                    ),callback:function ($$v) {_vm.$set(_vm.form.months_of_year_occupied, "value", $$v)},expression:"\n                                        form.months_of_year_occupied.value\n                                    "}})],1):_vm._e()],1)],1)])]}}])}),_c('Transition',[(_vm.rooms.show)?_c('listing-rental-rooms-component',{attrs:{"listing_id":_vm.listing_id,"main_subunit_id":_vm.main_subunit.id}}):_vm._e()],1)],1)],1),_c('Transition',[(_vm.outright_rental.show)?_c('b-card',{staticClass:"p-0"},[_c('b-card-body',{staticClass:"p-0"},[_c('h6',{staticClass:"heading-small text-muted mt-3"},[_vm._v(" Rental Information "),_c('Transition',[(_vm.$apollo.loading && !_vm.create_mode)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e()])],1),_c('validation-observer',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var handleSubmit = ref.handleSubmit;
return [_c('b-form',{ref:"rentalInformation",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.rental_information_form_on_submit)}}},[_c('div',{staticClass:"pl-lg-4"},[_c('b-row',[_c('b-col',{attrs:{"sm":"6","lg":"4"}},[_c('base-input',{staticClass:"mb-3",attrs:{"label":"Projected Rent*","type":"number","placeholder":"Projected Rent","name":"Projected Rent","rules":{ required: true },"disabled":_vm.$apollo.loading && !_vm.create_mode},on:{"keyup":_vm.debounced_rental_information_form_on_submit},model:{value:(
                                            _vm.form.rental_information
                                                .expected_rent
                                        ),callback:function ($$v) {_vm.$set(_vm.form.rental_information
                                                , "expected_rent", $$v)},expression:"\n                                            form.rental_information\n                                                .expected_rent\n                                        "}})],1)],1),_c('b-row',{},[_c('b-col',{attrs:{"lg":"6"}})],1)],1)])]}}],null,false,2909832422)})],1)],1):_vm._e()],1),_c('Transition',[(_vm.form.rental_information.current_lease)?_c('b-card',{staticClass:"p-0"},[_c('b-card-body',{staticClass:"p-0"},[_c('h6',{staticClass:"heading-small text-muted mt-3"},[_vm._v(" Current Rental Information "),_c('Transition',[(_vm.$apollo.loading && !_vm.create_mode)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e()])],1),_c('validation-observer',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"pl-lg-4"},[_c('b-row',[_c('b-col',{attrs:{"sm":"6","lg":"4"}},[_c('base-input',{staticClass:"mb-3",attrs:{"label":"Current Rent (R)*","type":"number","placeholder":"Current Rent","name":"Current Rent","rules":{ required: true },"disabled":_vm.$apollo.loading && !_vm.create_mode},model:{value:(
                                            _vm.form.current_rental_information
                                                .current_rent
                                        ),callback:function ($$v) {_vm.$set(_vm.form.current_rental_information
                                                , "current_rent", $$v)},expression:"\n                                            form.current_rental_information\n                                                .current_rent\n                                        "}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"6","lg":"4"}},[_c('base-input',{staticClass:"mb-3",attrs:{"label":"Deposit Amount (R)","type":"number","placeholder":"Deposit Amount","name":"Deposit Amount","rules":{ required: false },"disabled":_vm.$apollo.loading && !_vm.create_mode},model:{value:(
                                            _vm.form.current_rental_information
                                                .deposit_amount
                                        ),callback:function ($$v) {_vm.$set(_vm.form.current_rental_information
                                                , "deposit_amount", $$v)},expression:"\n                                            form.current_rental_information\n                                                .deposit_amount\n                                        "}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"6","lg":"4"}},[_c('base-input',{staticClass:"mb-3",attrs:{"label":"Annual Escalation (%)*","type":"number","placeholder":"Annual Escalation","name":"Annual Escalation","rules":{ required: true },"disabled":_vm.$apollo.loading && !_vm.create_mode},model:{value:(
                                            _vm.form.current_rental_information
                                                .annual_escalation_percentage
                                        ),callback:function ($$v) {_vm.$set(_vm.form.current_rental_information
                                                , "annual_escalation_percentage", $$v)},expression:"\n                                            form.current_rental_information\n                                                .annual_escalation_percentage\n                                        "}})],1)],1),_c('b-row',{},[_c('b-col',[_c('b-row',[_c('b-col',[_c('b-form-checkbox',{staticClass:"mb-3",attrs:{"disabled":_vm.$apollo.loading &&
                                                    !_vm.create_mode},model:{value:(
                                                    _vm.form
                                                        .current_rental_information
                                                        .includes_electricity
                                                ),callback:function ($$v) {_vm.$set(_vm.form
                                                        .current_rental_information
                                                        , "includes_electricity", $$v)},expression:"\n                                                    form\n                                                        .current_rental_information\n                                                        .includes_electricity\n                                                "}},[_vm._v(" Includes Electricity ")])],1)],1),_c('b-row',[_c('b-col',[_c('b-form-checkbox',{staticClass:"mb-3",attrs:{"disabled":_vm.$apollo.loading &&
                                                    !_vm.create_mode},model:{value:(
                                                    _vm.form
                                                        .current_rental_information
                                                        .includes_water
                                                ),callback:function ($$v) {_vm.$set(_vm.form
                                                        .current_rental_information
                                                        , "includes_water", $$v)},expression:"\n                                                    form\n                                                        .current_rental_information\n                                                        .includes_water\n                                                "}},[_vm._v(" Includes Water ")])],1)],1),_c('b-row',[_c('b-col',[_c('b-form-checkbox',{staticClass:"mb-3",attrs:{"disabled":_vm.$apollo.loading &&
                                                    !_vm.create_mode},model:{value:(
                                                    _vm.form
                                                        .current_rental_information
                                                        .includes_wifi
                                                ),callback:function ($$v) {_vm.$set(_vm.form
                                                        .current_rental_information
                                                        , "includes_wifi", $$v)},expression:"\n                                                    form\n                                                        .current_rental_information\n                                                        .includes_wifi\n                                                "}},[_vm._v(" Includes WiFi ")])],1)],1)],1)],1)],1)])]}}],null,false,3167089558)})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }