<template>
    <div>
        <div class="header pb-6 d-flex align-items-center">
            <!-- Mask -->
            <span class="mask bg-white opacity-8"></span>
            <!-- Header container -->
            <b-container fluid class="">
                <b-row>
                    <b-col class="mt-4">
                        <div
                            @click="$router.back()"
                            class="icon icon-shape rounded-circle shadow bg-default text-white"
                        >
                            <i class="fa fa-arrow-left"></i>
                        </div>
                    </b-col>
                    <b-col lg="11" class="">
                        <h1
                            class="display-1 text-orange pb-0 mb-0"
                            v-if="mode == 'create'"
                        >
                            Create a new Listing
                        </h1>
                        <h1 class="display-1 text-orange pb-0 mb-0" v-else>
                            Update your Listing
                        </h1>
                        <p class="text-default bold mt-0 mb-5 font-weight-bold">
                            Creating value is what we do!
                        </p>
                    </b-col>
                </b-row>
            </b-container>
        </div>

        <b-container class="mt--6">
            <b-row class="mb-4"> </b-row>
            <b-row>
                <b-col sm="12">
                    <listing-basic-detail
                        :listing_id="listing_id"
                        :mode="this.mode"
                        @created-listing-id="handle_created_listing_id"
                    ></listing-basic-detail>
                    <listing-subunit-detail-component
                        :listing_id="listing_id"
                        v-if="mode != 'create'"
                    >
                    </listing-subunit-detail-component>
                    <listing-renovation-component
                        :listing_id="listing_id"
                        v-if="mode != 'create'"
                    >
                    </listing-renovation-component>
                </b-col>
            </b-row>
            <Transition>
                <b-row v-if="mode == 'edit'">
                    <b-col sm="12">
                        <b-card
                            ><b-card-body class="p-0">
                                <h6 class="heading-small text-muted mb-4">
                                    Photos
                                </h6>
                                <listing-photo-manager
                                    :listing_id="listing_id"
                                /> </b-card-body
                        ></b-card>
                    </b-col>
                </b-row>
            </Transition>
            <Transition>
                <b-row v-if="mode == 'edit'">
                    <b-col sm="12">
                        <future-rental-plan-component
                            :listing_id="listing_id"
                        ></future-rental-plan-component>
                    </b-col>
                </b-row>
            </Transition>
        </b-container>
    </div>
</template>
<script>
import ListingBasicDetail from "@/views/Components/Listing/ListingBasicDetail.vue";
import FutureRentalPlanComponent from "@/views/Components/Listing/FutureRentalPlanComponent.vue";
import ListingRenovationComponent from "@/views/Components/Listing/ListingRenovationComponent.vue";
import ListingSubunitDetailComponent from "@/views/Components/Listing/ListingSubunitDetailComponent.vue";

import ListingPhotoManager from "@/views/Components/ListingPhotoManager.vue";

export default {
    name: "MyListings",
    components: {
        ListingBasicDetail,
        FutureRentalPlanComponent,
        ListingPhotoManager,
        ListingRenovationComponent,
        ListingSubunitDetailComponent,
    },
    props: {},
    data() {
        return {
            mode: "edit",
            listing_id: "",
        };
    },
    methods: {
        // Event Handlers
        handle_created_listing_id(id) {
            this.listing_id = id;
            this.mode = "edit";
        },

        get_listing_id() {
            if (this.mode == "edit") {
                this.listing_id = this.$route.query.id;
                if (this.listing_id == "" || this.listing_id == "undefined") {
                    this.$router.push({ name: "My Listings" });
                }
            }
        },

        get_mode() {
            let path_list = this.$route.path.split("/");
            if (["create", "edit"].includes(path_list[path_list.length - 1])) {
                this.mode = path_list[path_list.length - 1];
            }
        },

        // loadUserData() {
        //     this.user = utils.deepcopy(this.$store.getters.getUser);
        // },
    },
    created() {
        this.get_mode();
        this.get_listing_id();
    },

    beforeDestroy() {
        // global_event_emitter.$off("profile_update");
    },
};
</script>
<style></style>
