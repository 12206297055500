<template>
    <div>
        <!-- Modals -->
        <confirmation-modal
            v-model="modals.confirmation_delete.show"
            :confirmation_message="
                modals.confirmation_delete.confirmation_message
            "
            :confirmation_sub_message="
                modals.confirmation_delete.confirmation_sub_message
            "
            @confirmed="handle_confirmation_delete_modal"
        >
        </confirmation-modal>
        <div>
            <b-modal
                v-model="show"
                size="lg"
                id="crud-room-modal"
                :title="create_mode ? title_options['c'] : title_options['rud']"
                ok-title="Save"
                @hidden="handle_modal_hide()"
                @ok="handle_delete_button"
                @cancel="handle_ok"
                class="p-0"
            >
                <!-- Basic Information -->
                <b-card class="p-0">
                    <b-card-body class="p-0">
                        <h6 class="heading-small text-muted mt-3">
                            Basic Detail
                            <Transition>
                                <i
                                    v-if="$apollo.loading && !create_mode"
                                    class="fas fa-spinner fa-spin"
                                ></i>
                            </Transition>
                        </h6>

                        <validation-observer
                            v-slot="{ handleSubmit }"
                            ref="formValidator"
                        >
                            <b-form @submit.prevent="handleSubmit(on_submit)">
                                <input
                                    type="submit"
                                    ref="room_basic_detail_submit_button"
                                    hidden
                                />
                                <div class="pl-lg-4">
                                    <b-row>
                                        <b-col lg="6">
                                            <base-input
                                                label="Room Name*"
                                                class="mb-3"
                                                placeholder="Enter a name for this room."
                                                name="Room Name"
                                                :rules="{ required: true }"
                                                v-model="
                                                    form.basic_detail.room_name
                                                "
                                                :disabled="
                                                    $apollo.loading &&
                                                    !create_mode
                                                "
                                                @keyup="
                                                    debounced_on_keyup_basic_detail
                                                "
                                            >
                                            </base-input>
                                        </b-col>
                                        <b-col lg="6"> </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col sm="6" lg="3">
                                            <base-input
                                                label="Room Size m2"
                                                type="number"
                                                class="mb-3"
                                                placeholder="size"
                                                name="Room Size"
                                                :rules="{ required: false }"
                                                v-model="
                                                    form.basic_detail.room_size
                                                "
                                                :disabled="
                                                    $apollo.loading &&
                                                    !create_mode
                                                "
                                                @keyup="
                                                    debounced_on_keyup_basic_detail
                                                "
                                            >
                                            </base-input>
                                        </b-col>
                                        <b-col lg="6"> </b-col>
                                    </b-row>
                                    <b-row class="pt-3">
                                        <b-col lg="6">
                                            <Transition>
                                                <b-form-checkbox
                                                    v-if="
                                                        !form.basic_detail
                                                            .to_be_partitioned
                                                    "
                                                    class="mb-3"
                                                    v-model="
                                                        form.basic_detail
                                                            .already_partitioned
                                                    "
                                                    :disabled="
                                                        $apollo.loading &&
                                                        !create_mode
                                                    "
                                                    @change="
                                                        debounced_on_change_basic_detail
                                                    "
                                                >
                                                    Already Partitioned?
                                                </b-form-checkbox>
                                            </Transition>
                                        </b-col>

                                        <Transition>
                                            <b-col
                                                lg="6"
                                                v-if="
                                                    !form.basic_detail
                                                        .already_partitioned
                                                "
                                            >
                                                <b-form-checkbox
                                                    class="mb-3"
                                                    v-model="
                                                        form.basic_detail
                                                            .to_be_partitioned
                                                    "
                                                    :disabled="
                                                        $apollo.loading &&
                                                        !create_mode
                                                    "
                                                    @change="
                                                        debounced_on_change_basic_detail
                                                    "
                                                >
                                                    To be partitioned?
                                                </b-form-checkbox>
                                            </b-col>
                                        </Transition>
                                    </b-row>
                                    <b-row class="">
                                        <b-col>
                                            <b-row>
                                                <b-col>
                                                    <b-form-checkbox
                                                        class="mb-3"
                                                        v-model="
                                                            form.basic_detail
                                                                .shared_kitchen
                                                        "
                                                        :disabled="
                                                            $apollo.loading &&
                                                            !create_mode
                                                        "
                                                        @change="
                                                            debounced_on_change_basic_detail
                                                        "
                                                    >
                                                        Shared Kitchen?
                                                    </b-form-checkbox>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col>
                                                    <b-form-checkbox
                                                        class="mb-3"
                                                        v-model="
                                                            form.basic_detail
                                                                .shared_bathroom
                                                        "
                                                        :disabled="
                                                            $apollo.loading &&
                                                            !create_mode
                                                        "
                                                        @change="
                                                            debounced_on_change_basic_detail
                                                        "
                                                    >
                                                        Shared Bathroom?
                                                    </b-form-checkbox>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                        <b-col>
                                            <b-row>
                                                <b-col>
                                                    <b-form-checkbox
                                                        class="mb-3"
                                                        v-model="
                                                            form.basic_detail
                                                                .furnished
                                                        "
                                                        :disabled="
                                                            $apollo.loading &&
                                                            !create_mode
                                                        "
                                                        @change="
                                                            debounced_on_change_basic_detail
                                                        "
                                                    >
                                                        Furnished?
                                                    </b-form-checkbox>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-form>
                        </validation-observer>
                    </b-card-body>
                </b-card>

                <!-- Financial Information -->
                <b-card class="p-0" v-if="internal_subunit_id">
                    <b-card-body class="p-0">
                        <h6 class="heading-small text-muted mt-3">
                            Rental Information
                            <Transition>
                                <i
                                    v-if="$apollo.loading && !create_mode"
                                    class="fas fa-spinner fa-spin"
                                ></i>
                            </Transition>
                        </h6>
                        <validation-observer
                            v-slot="{ handleSubmit }"
                            ref="formValidator"
                        >
                            <b-form @submit.prevent="handleSubmit(on_submit)">
                                <input
                                    type="submit"
                                    ref="room_rental_information_submit_button"
                                    hidden
                                />
                                <div class="pl-lg-4">
                                    <b-row>
                                        <b-col sm="6" lg="4">
                                            <base-input
                                                label="Projected Rental Income (R)*"
                                                type="number"
                                                class="mb-3"
                                                placeholder="Projected Rental Income"
                                                name="Projected Rental Income"
                                                :rules="{ required: true }"
                                                v-model="
                                                    form.rental_information
                                                        .expected_rent
                                                "
                                                :disabled="
                                                    $apollo.loading &&
                                                    !create_mode
                                                "
                                                @keyup="
                                                    debounced_on_keyup_rental_information
                                                "
                                            >
                                            </base-input>
                                        </b-col>
                                    </b-row>
                                    <!-- <b-row class="">
                                        <b-col lg="6">
                                            <b-form-checkbox
                                                class="mb-3"
                                                v-model="
                                                    form.rental_information
                                                        .current_lease
                                                "
                                                :disabled="
                                                    $apollo.loading &&
                                                    !create_mode
                                                "
                                            >
                                                Current Lease?
                                            </b-form-checkbox>
                                        </b-col>
                                    </b-row> -->
                                </div>
                            </b-form>
                        </validation-observer>
                    </b-card-body>
                </b-card>
                <Transition>
                    <b-card
                        class="p-0"
                        v-if="form.rental_information.current_lease"
                    >
                        <b-card-body class="p-0">
                            <h6 class="heading-small text-muted mt-3">
                                Current Rental Information
                                <Transition>
                                    <i
                                        v-if="$apollo.loading && !create_mode"
                                        class="fas fa-spinner fa-spin"
                                    ></i>
                                </Transition>
                            </h6>
                            <validation-observer
                                v-slot="{ handleSubmit }"
                                ref="formValidator"
                            >
                                <b-form
                                    @submit.prevent="handleSubmit(onSubmit)"
                                >
                                    <div class="pl-lg-4">
                                        <b-row>
                                            <b-col sm="6" lg="4">
                                                <base-input
                                                    label="Current Rent (R)*"
                                                    type="number"
                                                    class="mb-3"
                                                    placeholder="Current Rent"
                                                    name="Current Rent"
                                                    :rules="{ required: true }"
                                                    v-model="
                                                        form
                                                            .current_rental_information
                                                            .current_rent
                                                    "
                                                    :disabled="
                                                        $apollo.loading &&
                                                        !create_mode
                                                    "
                                                >
                                                </base-input>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col sm="6" lg="4">
                                                <base-input
                                                    label="Deposit Amount (R)"
                                                    type="number"
                                                    class="mb-3"
                                                    placeholder="Deposit Amount"
                                                    name="Deposit Amount"
                                                    :rules="{ required: false }"
                                                    v-model="
                                                        form
                                                            .current_rental_information
                                                            .deposit_amount
                                                    "
                                                    :disabled="
                                                        $apollo.loading &&
                                                        !create_mode
                                                    "
                                                >
                                                </base-input>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col sm="6" lg="4">
                                                <base-input
                                                    label="Annual Escalation (%)*"
                                                    type="number"
                                                    class="mb-3"
                                                    placeholder="Annual Escalation"
                                                    name="Annual Escalation"
                                                    :rules="{ required: true }"
                                                    v-model="
                                                        form
                                                            .current_rental_information
                                                            .annual_escalation_percentage
                                                    "
                                                    :disabled="
                                                        $apollo.loading &&
                                                        !create_mode
                                                    "
                                                >
                                                </base-input>
                                            </b-col>
                                        </b-row>
                                        <b-row class="">
                                            <b-col>
                                                <b-row>
                                                    <b-col>
                                                        <b-form-checkbox
                                                            class="mb-3"
                                                            v-model="
                                                                form
                                                                    .current_rental_information
                                                                    .includes_electricity
                                                            "
                                                            :disabled="
                                                                $apollo.loading &&
                                                                !create_mode
                                                            "
                                                        >
                                                            Includes Electricity
                                                        </b-form-checkbox>
                                                    </b-col>
                                                </b-row>
                                                <b-row>
                                                    <b-col>
                                                        <b-form-checkbox
                                                            class="mb-3"
                                                            v-model="
                                                                form
                                                                    .current_rental_information
                                                                    .includes_water
                                                            "
                                                            :disabled="
                                                                $apollo.loading &&
                                                                !create_mode
                                                            "
                                                        >
                                                            Includes Water
                                                        </b-form-checkbox>
                                                    </b-col>
                                                </b-row>
                                                <b-row>
                                                    <b-col>
                                                        <b-form-checkbox
                                                            class="mb-3"
                                                            v-model="
                                                                form
                                                                    .current_rental_information
                                                                    .includes_wifi
                                                            "
                                                            :disabled="
                                                                $apollo.loading &&
                                                                !create_mode
                                                            "
                                                        >
                                                            Includes WiFi
                                                        </b-form-checkbox>
                                                    </b-col>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                    </div>
                                </b-form>
                            </validation-observer>
                        </b-card-body>
                    </b-card>
                </Transition>
                <template #modal-footer="{ ok, cancel }">
                    <b-button
                        v-if="internal_subunit_id"
                        variant="danger"
                        @click="ok()"
                    >
                        Delete
                    </b-button>
                    <b-button variant="default" @click="cancel()">
                        Save
                    </b-button>
                </template>
            </b-modal>
        </div>
    </div>
</template>
<script>
// Modules
import { debounce } from "debounce";

// Standard Components
import ConfirmationModal from "@/views/Components/ConfirmationModal.vue";

//Queries
import { GET_PROPERTY_SUBUNIT } from "@/graphql/queries";
import { GET_ALL_PROPERTY_SUBUNIT_TYPES } from "@/graphql/queries";
import { GET_ALL_PROPERTY_RENTAL_APPROACH } from "@/graphql/queries";

// Mutations
import { CREATE_PROPERTY_SUBUNIT } from "@/graphql/mutations";
import { UPDATE_PROPERTY_SUBUNIT } from "@/graphql/mutations";
import { DELETE_PROPERTY_SUBUNITS } from "@/graphql/mutations";

export default {
    name: "CrudRoomModal",
    emits: ["updated"],
    components: {
        ConfirmationModal,
    },
    apollo: {
        get_property_subunit: {
            query: GET_PROPERTY_SUBUNIT,
            result(data) {
                this.handle_get_property_subunit(data);
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            update(data) {
                this.apollo_data.get_property_subunit = data;
            },
            skip: true,
            variables() {
                return {
                    subunit_id: this.subunit_id,
                };
            },
        },
        get_property_subunit_types: {
            query: GET_ALL_PROPERTY_SUBUNIT_TYPES,
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors);
                return false;
            },
            result(data) {
                this.handle_get_property_subunit_types(data);
            },
            update(data) {
                this.apollo_data.get_property_subunit_types = data;
            },
        },
        get_rental_approaches: {
            query: GET_ALL_PROPERTY_RENTAL_APPROACH,
            result(data) {
                this.handle_get_rental_approaches(data);
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors);
                return false;
            },
            update(data) {
                this.apollo_data.get_rental_approaches = data;
            },
        },
    },

    props: {
        value: Boolean,
        subunit_id: {
            type: String,
            description: "ID of the subunit, can be blank on create.",
            default: "",
        },
        main_subunit_id: {
            type: String,
            description: "ID of the main subunit parent",
            default: "",
        },
        listing_id: {
            type: String,
            description: "ID of the parent listing",
            default: "",
        },
        create_mode: {
            type: Boolean,
            description: "Operation mode Create or Update Read Delete ",
            default: true,
        },
    },

    data() {
        return {
            show: this.value,
            title_options: {
                c: "Create Room",
                rud: "Edit Room",
            },
            _create_mode: false,
            internal_subunit_id: "",
            form: {
                basic_detail: {
                    room_name: "",
                    room_size: "",
                    already_partitioned: false,
                    to_be_partitioned: false,
                    shared_kitchen: false,
                    shared_bathroom: false,
                    furnished: false,
                },
                rental_information: {
                    expected_rent: "",
                    current_lease: false,
                },
                current_rental_information: {
                    current_rent: "",
                    deposit_amount: 0,
                    annual_escalation_percentage: "",
                    includes_electricity: false,
                    includes_water: false,
                    includes_wifi: false,
                },
            },
            apollo_data: {
                get_property_subunit: [],
                get_property_subunit_types: [],
                get_rental_approaches: [],
            },
            select_ids: {
                room_subunit_type_id: "",
                outright_rental_approach_id: "",
            },
            modals: {
                confirmation_delete: {
                    show: false,
                    confirmation_message: "You are about to delete this room.",
                    confirmation_sub_message: "Are you sure?",
                },
            },
        };
    },
    methods: {
        // Reset Form
        reset_form() {
            this.form = {
                basic_detail: {
                    room_name: "",
                    room_size: "",
                    already_partitioned: false,
                    to_be_partitioned: false,
                    shared_kitchen: false,
                    shared_bathroom: false,
                    furnished: false,
                },
                rental_information: {
                    expected_rent: "",
                    current_lease: false,
                },
                current_rental_information: {
                    current_rent: "",
                    deposit_amount: 0,
                    annual_escalation_percentage: "",
                    includes_electricity: false,
                    includes_water: false,
                    includes_wifi: false,
                },
            };
        },

        // Display functions
        formatted_currency(value) {
            return value.toLocaleString();
        },

        // Event handler functions
        handle_modal_hide() {
            this.show = false;
            this.reset_form();
        },

        handle_ok(bvModalEvent) {
            bvModalEvent.preventDefault();
            // this.$refs.room_basic_detail_submit_button.click();

            this.$nextTick(() => {
                this.$bvModal.hide("crud-room-modal");
            });
        },

        // Apollo handlers
        handle_get_property_subunit(data) {
            let flattened = graph_utils.flatten_objects_recursive(
                graph_utils.apollo_to_obj_recursive(data)
            );

            this.form.basic_detail = {
                room_name: flattened.data__propertySubunit__description,
                room_size: flattened.data__propertySubunit__sizeM2,
                already_partitioned:
                    flattened.data__propertySubunit__isPartitioned,
                to_be_partitioned: flattened.data__propertySubunit__toBeCreated,
                shared_kitchen:
                    flattened.data__propertySubunit__hasSharedKitchen,
                shared_bathroom:
                    flattened.data__propertySubunit__hasSharedBathroom,
                furnished: flattened.data__propertySubunit__isFurnished,
            };

            this.form.rental_information = {
                expected_rent:
                    flattened.data__propertySubunit__expectedRentalIncome__amount,
                current_lease: false,
            };
            this.form.rental_information.expected_rent =
                flattened.data__propertySubunit__expectedRentalIncome__amount;
            // if (flattened.data__propertySubunit__leases.length > 0) {
            //     this.form.rental_information.current_lease = true;

            //     this.form.current_rental_information = {
            //         current_rent:
            //             flattened.data__propertySubunit__leases[0]
            //                 .monthlyRent__amount,
            //         deposit_amount:
            //             flattened.data__propertySubunit__leases[0]
            //                 .depositAmount__amount,
            //         annual_escalation_percentage:
            //             flattened.data__propertySubunit__leases[0]
            //                 .annualRentalEscalationPercentage,
            //         includes_electricity:
            //             flattened.data__propertySubunit__leases[0]
            //                 .includesElectricity,
            //         includes_water:
            //             flattened.data__propertySubunit__leases[0]
            //                 .includesWater,
            //         includes_wifi:
            //             flattened.data__propertySubunit__leases[0].includesWifi,
            //     };
            // }
        },

        handle_get_property_subunit_types(data) {
            let flattened = graph_utils.flatten_objects_recursive(
                graph_utils.apollo_to_obj_recursive(data.data)
            );
            if ("allPropertySubunitType" in flattened) {
                for (
                    let i = 0;
                    i < flattened.allPropertySubunitType.length;
                    i++
                ) {
                    if (flattened.allPropertySubunitType[i].name == "ROOM") {
                        this.select_ids.room_subunit_type_id =
                            flattened.allPropertySubunitType[i].id;
                    }
                }
            }
        },

        enable_get_property_subunit() {
            if (this.subunit_id !== "" && !this.create_mode) {
                if (!this.$apollo.queries.get_property_subunit.skip) {
                    this.$apollo.queries.get_property_subunit.refetch();
                } else {
                    this.$apollo.queries.get_property_subunit.skip = false;
                    this.$apollo.queries.get_property_subunit.start();
                }
            } else {
                if (this.$apollo.queries.get_property_subunit.skip) {
                    this.$apollo.queries.get_property_subunit.skip = true;
                }
                this.reset_form();
            }
        },

        handle_get_rental_approaches(data) {
            let flattened = graph_utils.flatten_objects_recursive(
                graph_utils.apollo_to_obj_recursive(data.data)
            );

            if ("allPropertyRentalApproach" in flattened) {
                for (
                    let i = 0;
                    i < flattened.allPropertyRentalApproach.length;
                    i++
                ) {
                    if (
                        flattened.allPropertyRentalApproach[i].name ==
                        "OUTRIGHT"
                    ) {
                        this.select_ids.outright_rental_approach_id =
                            flattened.allPropertyRentalApproach[i].id;
                    }
                }
            }
        },
        // utils
        get_subunit_variables() {
            let variables = {
                listing_id: this.listing_id, //prop
                subunit_type_id: this.select_ids.room_subunit_type_id,
                allows_subletting: false,
                max_occupant_count: 0,
                future_rental_apporach:
                    this.select_ids.outright_rental_approach_id,
                parent_subunit_id: this.main_subunit_id, //prop
                has_shared_garden: false,
                has_shared_kitchen: this.form.basic_detail.shared_kitchen,
                is_partitioned: this.form.basic_detail.already_partitioned,
                to_be_created: this.form.basic_detail.to_be_partitioned,
                has_shared_bathroom: this.form.basic_detail.shared_bathroom,
                description: this.form.basic_detail.room_name,
            };
            if (this.form.rental_information.expected_rent) {
                variables.expected_rental_income =
                    this.form.rental_information.expected_rent;
            }
            if (this.form.basic_detail.room_size) {
                variables.size_m2 = this.form.basic_detail.room_size;
            }
            if (this.internal_subunit_id != "") {
                variables.subunit_id = this.internal_subunit_id;
            }
            return variables;
        },

        // mutations
        create_subunit() {
            this.$apollo
                .mutate({
                    mutation: CREATE_PROPERTY_SUBUNIT,
                    variables: this.get_subunit_variables(),
                })
                .then((res) => {
                    this.$emit("updated");
                    if (
                        "data" in res &&
                        "propertyCreateSubunit" in res.data &&
                        "subunit" in res.data.propertyCreateSubunit
                    ) {
                        this.internal_subunit_id =
                            res.data.propertyCreateSubunit.subunit.id;
                        this._create_mode = false;
                    }
                });
        },
        update_subunit() {
            this.$apollo
                .mutate({
                    mutation: UPDATE_PROPERTY_SUBUNIT,
                    variables: this.get_subunit_variables(),
                })
                .then((res) => {
                    this.$emit("updated");
                });
        },

        delete_room(id) {
            let variables = {
                subunit_id: id,
            };
            this.$apollo
                .mutate({
                    mutation: DELETE_PROPERTY_SUBUNITS,
                    variables: variables,
                })
                .then((res) => {
                    setTimeout(() => {
                        this.$emit("updated");
                    }, 500);
                });
        },

        // event handlers

        debounced_on_keyup_basic_detail: debounce(function () {
            this.$refs.room_basic_detail_submit_button.click();
        }, 1000),
        debounced_on_keyup_rental_information: debounce(function () {
            this.$refs.room_rental_information_submit_button.click();
        }, 1000),
        debounced_on_change_basic_detail: debounce(function () {
            this.$refs.room_basic_detail_submit_button.click();
        }, 1000),

        on_submit() {
            if (this._create_mode) {
                this.create_subunit();
            } else {
                this.update_subunit();
            }
        },

        handle_delete_button(bvModalEvent) {
            bvModalEvent.preventDefault();
            this.modals.confirmation_delete.show = true;
        },

        handle_confirmation_delete_modal(confirmed) {
            if (confirmed) {
                this.delete_room(this.internal_subunit_id);
                this.$emit("updated");
                this.handle_modal_hide();
            }
        },
    },
    watch: {
        show(new_show) {
            this.$emit("input", new_show);
        },
        subunit_id() {
            if (this.subunit_id) {
                this.enable_get_property_subunit();
            }
        },
        value(new_value) {
            this.show = new_value;

            this._create_mode = this.create_mode;
            this.internal_subunit_id = this.subunit_id;
            if (this.subunit_id != "" && new_value) {
                this.enable_get_property_subunit();
            }
        },
    },
};
</script>
<style></style>
