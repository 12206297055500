<template>
    <div>
        <!-- Header -->
        <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
            <b-container class="container">
                <div class="header-body text-center mb-7">
                    <b-row class="justify-content-center">
                        <b-col xl="5" lg="6" md="8" class="px-5">
                            <h1 class="text-white">Create an account</h1>
                            <p class="text-lead text-white">
                                Let's start your investRand journey right now
                            </p>
                        </b-col>
                    </b-row>
                </div>
            </b-container>
            <div class="separator separator-bottom separator-skew zindex-100">
                <svg
                    x="0"
                    y="0"
                    viewBox="0 0 2560 100"
                    preserveAspectRatio="none"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <polygon
                        class="fill-default"
                        points="2560 0 2560 100 0 100"
                    ></polygon>
                </svg>
            </div>
        </div>
        <!-- Page content -->
        <b-container class="mt--8 pb-5">
            <!-- Table -->
            <b-row class="justify-content-center">
                <b-col lg="6" md="8">
                    <b-card no-body class="bg-secondary border-0">
                        <div class="row justify-content-center">
                            <div class="col-6 mt-5">
                                <img
                                    src="img/brand/logos/investRand.svg"
                                    class="img-fluid"
                                />
                            </div>
                        </div>
                        <!--  <b-card-header class="bg-transparent pb-5">
                            <div class="text-muted text-center mt-2 mb-4">
                                <small>Register with</small>
                            </div>
                            <div class="text-center">
                                <a href="#" class="btn btn-neutral btn-icon">
                                    <span class="btn-inner--icon"
                                        ><img src="img/icons/common/google.svg"
                                    /></span>
                                    <span class="btn-inner--text">Google</span>
                                </a>
                                <a href="#" class="btn btn-neutral btn-icon">
                                    <span class="btn-inner--icon"
                                        ><img src="img/icons/common/apple.svg"
                                    /></span>
                                    <span class="btn-inner--text">Apple</span>
                                </a>
                            </div>
                        </b-card-header> -->
                        <b-card-body class="px-lg-5 py-lg-5">
                            <!-- <div class="text-center text-muted mb-4">
                                <small>Or register with credentials</small>
                            </div> -->

                            <validation-observer
                                v-slot="{ handleSubmit }"
                                ref="formValidator"
                            >
                                <b-form
                                    role="form"
                                    @submit.prevent="handleSubmit(onSubmit)"
                                >
                                    <base-input
                                        alternative
                                        class="mb-3"
                                        prepend-icon="ni ni-circle-08"
                                        placeholder="Name"
                                        name="Name"
                                        :rules="{ required: true }"
                                        v-model="model.name"
                                    >
                                    </base-input>
                                    <base-input
                                        alternative
                                        class="mb-3"
                                        prepend-icon="ni ni-circle-08"
                                        placeholder="Surname"
                                        name="Surname"
                                        :rules="{ required: true }"
                                        v-model="model.surname"
                                    >
                                    </base-input>

                                    <base-input
                                        alternative
                                        class="mb-3"
                                        prepend-icon="ni ni-email-83"
                                        placeholder="Email (will be used to validate account)"
                                        name="Email"
                                        :rules="{
                                            required: true,
                                            email: true,
                                        }"
                                        no_spaces
                                        v-model="model.email"
                                    >
                                    </base-input>
                                    <!-- no_spaces: true -->
                                    <base-input
                                        alternative
                                        class="mb-3"
                                        name="Cell Number"
                                        :rules="{ required: true, digits: 10 }"
                                        prepend-icon="fa fa-phone"
                                        placeholder="Cell Number (will be used to validate account)"
                                        v-model="model.phone"
                                        :value="model.phone ? model.phone : ''"
                                        no_spaces
                                    ></base-input>

                                    <base-input
                                        alternative
                                        class="mb-3"
                                        prepend-icon="ni ni-lock-circle-open"
                                        placeholder="password"
                                        type="password"
                                        name="Password"
                                        :rules="{ required: true, min: 8 }"
                                        v-model="model.password"
                                    >
                                    </base-input>
                                    <!-- <div class="text-muted font-italic"><small>password strength: <span
                    class="text-success font-weight-700">strong</span></small></div> -->
                                    <b-row class="my-4">
                                        <b-col cols="12">
                                            <base-input
                                                :rules="{
                                                    required: {
                                                        allowFalse: false,
                                                    },
                                                }"
                                                name="Privacy & Terms and Conditions"
                                                Policy
                                            >
                                                <b-form-checkbox
                                                    v-model="model.agree"
                                                >
                                                    <span class="text-muted"
                                                        >I agree with the
                                                        <a
                                                            href="#/privacy"
                                                            target="_blank"
                                                            >Privacy Policy</a
                                                        >
                                                        &
                                                        <a
                                                            href="#/tnc"
                                                            target="_blank"
                                                            >Terms and
                                                            Conditions</a
                                                        >
                                                    </span>
                                                </b-form-checkbox>
                                            </base-input>
                                        </b-col>
                                    </b-row>

                                    <div class="text-center">
                                        <div
                                            v-for="error_message in error_messages"
                                            :key="error_message"
                                        >
                                            <div
                                                class="invalid-feedback"
                                                style="display: block"
                                            >
                                                {{ error_message }}
                                            </div>
                                        </div>

                                        <base-button
                                            type="primary"
                                            :pill="true"
                                            native-type="submit"
                                            class="my-4"
                                            :loading="is_loading"
                                            :disabled="is_loading"
                                            >Create account</base-button
                                        >
                                    </div>
                                </b-form>
                            </validation-observer>
                            <b-row>
                                <b-col cols="12" class="text-center">
                                    <base-button
                                        type="default"
                                        :pill="true"
                                        @click="route_to_login"
                                        class=""
                                        :disabled="is_loading"
                                        >Have an Account? Log in</base-button
                                    >
                                    <!-- <router-link :to="{ path: '/login', query: { next: this.next } }"
                                        ><small
                                            >Already have an account? Log
                                            in.</small
                                        ></router-link
                                    > -->
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import rest_api from "@/rest_api";
import { GET_ALL_USER_ACCOUNT_ACCOUNT } from "@/graphql/queries";
import { MUTATE_USER_PROFILE_CONTACT_DETAIL } from "@/graphql/mutations";

export default {
    name: "register",
    data() {
        return {
            model: {
                name: "",
                surname: "",
                email: "",
                password: "",
                phone: null,
                agree: false,
            },
            is_loading: false,
            error_messages: [],
            next: null,
        };
    },
    methods: {
        onSubmit() {
            this.is_loading = true;
            this.error_messages = [];
            rest_api.user
                .register(
                    this.model.name,
                    this.model.surname,
                    this.model.email,
                    this.model.password
                )
                .then((res) => {
                    let variables = {
                        phoneNumber: this.model.phone,
                    };
                    return setTimeout(() => {
                        return this.$apollo.mutate({
                            mutation: MUTATE_USER_PROFILE_CONTACT_DETAIL,
                            variables: variables,
                        });
                    }, 1000);
                })
                .then((res) => {
                    setTimeout(() => {
                        return this.$apollo
                            .query({
                                query: GET_ALL_USER_ACCOUNT_ACCOUNT,
                                variables: {
                                    user: store.getters.getUserIdB64,
                                },
                            })
                            .then((res) => {
                                let flattened =
                                    graph_utils.flatten_objects_recursive(
                                        graph_utils.apollo_to_obj_recursive(res)
                                    );
                                if (
                                    "data__allUserAccountAccount" in flattened
                                ) {
                                    if (
                                        flattened.data__allUserAccountAccount
                                            .length > 0
                                    ) {
                                        store.commit("setAccount", {
                                            id_b64: flattened
                                                .data__allUserAccountAccount[0]
                                                .id,
                                            uid: flattened
                                                .data__allUserAccountAccount[0]
                                                .uid,
                                        });
                                    }
                                }
                            });
                    }, 2000);
                })
                .then((response) => {
                    this.$emit("updatedLoggedInStatus");
                    setTimeout(() => {
                        if (this.next !== null) {
                            this.$router.push({ path: this.next });
                        } else {
                            this.$router.push({ path: "/" });
                        }
                    }, 500);
                })
                .catch((err) => {
                    this.is_loading = false;
                    this.error_messages = err;
                });
        },

        route_to_login() {
            this.$router.push({ path: "/login", query: { next: this.next } });
        },
        get_next() {
            if (this.$route.query.next) {
                this.next = this.$route.query.next;
            }
        },
    },
    mounted() {
        this.get_next();
    },
    watch: {
        $route: {
            immediate: true,
            handler: function () {
                this.get_next();
            },
        },
    },
};
</script>
<style></style>
