<template>
    <div class="dashboard-content-window">
        <div class="header bg-gradient-success pt-6 py-lg-6 pt-lg-7">
            <b-container>
                <div class="header-body text-center">
                    <b-row class="justify-content-center">
                        <b-col xl="5" lg="6" md="8" class="px-5">
                            <h1 class="text-white display-1">
                                Service Provider
                            </h1>
                            <p class="text-lead text-white">
                                Let's build your team
                            </p>
                        </b-col>
                    </b-row>
                </div>
            </b-container>

            <b-container>
                <b-row>
                    <b-col class="my-2">
                        <div
                            @click="$router.back()"
                            class="icon icon-shape rounded-circle shadow bg-white text-default"
                        >
                            <i class="fa fa-arrow-left"></i>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="12" v-if="$apollo.loading">
                        <h1 class="text-center">
                            <i class="fas fa-spinner fa-spin"></i>
                        </h1>
                    </b-col>
                    <b-col sm="12" md="8" v-else>
                        <service-provider-detail-component-un-auth
                            :id="service_provider.id"
                            @rating-id="update_rating_id"
                        >
                        </service-provider-detail-component-un-auth>
                    </b-col>
                    <!-- Sidebar -->
                    <b-col
                        class="order-sm-1"
                        sm="12"
                        md="4"
                        v-if="!$apollo.loading"
                    >
                        <profile-rating-widget-un-auth
                            v-if="service_provider.rating_id"
                            :rating_id="service_provider.rating_id"
                        ></profile-rating-widget-un-auth>
                    </b-col>
                </b-row>
                <!-- <b-row>
                    <b-col xs="12" md="8">
                        <listing-finances></listing-finances>
                    </b-col>
                </b-row> -->
            </b-container>
        </div>
    </div>
</template>
<script>
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Vue from "vue";
Vue.use(VueViewer);
// Components
import ServiceProviderDetailComponentUnAuth from "@/views/Components/ServiceProvider/ServiceProviderDetailComponentUnAuth.vue";
import ProfileRatingWidgetUnAuth from "@/views/Components/Rating/ProfileRatingWidgetUnAuth.vue";

// Queries

// Mutations

export default {
    name: "ViewServiceProvider",
    components: {
        ServiceProviderDetailComponentUnAuth,
        ProfileRatingWidgetUnAuth,
    },
    apollo: {},

    data() {
        return {
            service_provider: {
                id: "",
                rating_id: null,
            },
        };
    },
    methods: {
        // Apollo Handlers

        // Apollo triggers

        get_service_provider_id() {
            this.service_provider.id = this.$route.query.id;
        },

        //Event handlers
        update_rating_id(rating_id) {
            this.service_provider.rating_id = rating_id;
        },
    },
    mounted() {
        this.get_service_provider_id();
    },
    created() {},
    computed: {},
};
</script>
<style></style>
