<template>
    <div>
        <div class="header pb-6 align-items-center">
            <!-- Mask -->
            <span class="mask bg-white opacity-8"></span>
            <!-- Header container -->
            <b-container fluid class="align-items-center">
                <b-row>
                    <b-col class="mt-3">
                        <div
                            @click="$router.back()"
                            class="icon icon-shape rounded-circle shadow bg-default text-white"
                        >
                            <i class="fa fa-arrow-left"></i>
                        </div>
                    </b-col>
                    <b-col lg="11">
                        <h1 class="display-1 text-orange pb-0 mb-0">
                            Service Provider
                        </h1>
                    </b-col>
                </b-row>
            </b-container>
        </div>

        <b-container class="mt--6">
            <b-row>
                <b-col sm="12" v-if="$apollo.loading">
                    <h1 class="text-center">
                        <i class="fas fa-spinner fa-spin"></i>
                    </h1>
                </b-col>
                <b-col sm="12" md="8" v-else>
                    <service-provider-detail-component
                        :id="service_provider.id"
                        @rating-id="update_rating_id"
                    >
                    </service-provider-detail-component>
                </b-col>
                <!-- Sidebar -->
                <b-col
                    class="order-sm-1"
                    sm="12"
                    md="4"
                    v-if="!$apollo.loading"
                >
                    <profile-rating-widget
                        v-if="service_provider.rating_id"
                        :rating_id="service_provider.rating_id"
                    ></profile-rating-widget>
                </b-col>
            </b-row>
            <!-- <b-row>
                <b-col xs="12" md="8">
                    <listing-finances></listing-finances>
                </b-col>
            </b-row> -->
        </b-container>
    </div>
</template>
<script>
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Vue from "vue";
Vue.use(VueViewer);
// Components
import ServiceProviderDetailComponent from "@/views/Components/ServiceProvider/ServiceProviderDetailComponent.vue";
import ProfileRatingWidget from "@/views/Components/Rating/ProfileRatingWidget.vue";

// Queries

// Mutations

export default {
    name: "ViewServiceProvider",
    components: {
        ServiceProviderDetailComponent,
        ProfileRatingWidget,
    },
    apollo: {},

    data() {
        return {
            service_provider: {
                id: "",
                rating_id: null,
            },
        };
    },
    methods: {
        // Apollo Handlers

        // Apollo triggers

        get_service_provider_id() {
            this.service_provider.id = this.$route.query.id;
        },

        //Event handlers
        update_rating_id(rating_id) {
            this.service_provider.rating_id = rating_id;
        },
    },
    mounted() {
        this.get_service_provider_id();
    },
    created() {},
    computed: {},
};
</script>
<style>
.profile-header {
    background-image: url(/img/theme/profile-cover.jpg);
    background-size: cover;
    background-position: center top;
    min-height: 500px;
}
</style>
