<template>
  <b-card>
    <b-card-body class="p-0">
      <h6 class="heading-small text-muted mb-4">
        Finance
        <Transition>
          <i
            v-if="$apollo.loading || bond_form.loading"
            class="fas fa-spinner fa-spin"
          ></i>
        </Transition>
      </h6>
      <!-- @submit.prevent="handleSubmit(on_submit)" -->
      <!-- v-model="renovations.planned" -->
      <b-form>
        <b-form-checkbox
          class="mb-3"
          v-model="finance_type_form.cash_purchase"
          @change="change_cash_purchase_debounced"
          disabled
        >
          Cash Purchase
        </b-form-checkbox>
      </b-form>

      <div v-if="!finance_type_form.cash_purchase">
        <validation-observer v-slot="{ handleSubmit }" ref="bond_formValidator">
          <b-form @submit.prevent="handleSubmit(on_submit)">
            <input type="submit" ref="bond_form_submit_button" hidden />
            <b-row>
              <b-col md="3">
                <base-input
                  label="Negotiated Price (R)*"
                  class="mb-3"
                  type="number"
                  placeholder="Enter an amount"
                  name="Negotiated Price"
                  :rules="{ required: true }"
                  v-model="static_values.buying_price"
                  disabled
                >
                </base-input>
              </b-col>
              <b-col md="3">
                <base-input
                  label="Deposit (R)*"
                  class="mb-3"
                  type="number"
                  placeholder="Enter an amount"
                  name="Deposit"
                  :rules="{ required: true }"
                  v-model="bond_form.deposit"
                  :disabled="$apollo.loading && !bond_form.create_mode"
                  @keyup="input_on_key_up_debounced"
                >
                </base-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3">
                <base-input
                  label="Interest Rate (%)*"
                  class="mb-3"
                  type="number"
                  step=".01"
                  placeholder="Enter an amount"
                  name="Interest Rate"
                  :rules="{ required: true }"
                  v-model="bond_form.interest_rate"
                  :disabled="$apollo.loading && !bond_form.create_mode"
                  @keyup="input_on_key_up_debounced"
                >
                </base-input>
              </b-col>
              <b-col md="3">
                <base-input
                  label="Term (Years)*"
                  class="mb-3"
                  type="number"
                  placeholder="Enter an amount"
                  name="Term"
                  :rules="{ required: true }"
                  v-model="bond_form.term_years"
                  :disabled="$apollo.loading && !bond_form.create_mode"
                  @keyup="input_on_key_up_debounced"
                >
                </base-input>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
      <b-row class="pt-md-2" v-if="!finance_type_form.cash_purchase">
        <!-- Title -->
        <b-col sm="12" md="6" class="ml-4 text-left align-middle my-auto">
          <h2>Monthly Bond Repayment</h2>
        </b-col>
        <!-- Cost amount -->
        <b-col
          class="mr-4 ml-4 ml-md-0 text-left text-md-right align-middle my-auto"
        >
          <h1 class="display-3 text-orange text-nowrap">
            {{ monthly_repayment.currency }}
            {{ bond_formatted_currency(monthly_repayment.amount) }}
          </h1>
        </b-col>
        <b-col sm="1"></b-col>
      </b-row> </b-card-body
  ></b-card>
</template>

<script>
// Modules
import { debounce } from "debounce";

// Queries
import { GET_INVESTMENT_INVESTMENT_FINANCING } from "@/graphql/queries";
import { GET_ALL_INVESTMENT_FINANCING_TYPES } from "@/graphql/queries";

// Mutations
import { UPDATE_INVESTMENT_BOND } from "@/graphql/mutations";
import { CREATE_INVESTMENT_BOND } from "@/graphql/mutations";
import { DELETE_INVESTMENT_BOND } from "@/graphql/mutations";
import { UPDATE_INVESTMENT_FINANCING_TYPE } from "@/graphql/mutations";
import { setTimeout } from "timers";

export default {
  name: "InvestmentFinancing",
  components: {},
  props: {
    investment_id: {
      type: String,
      description: "ID of the investment in question",
      default: "",
    },
  },
  apollo: {
    get_investment_financing: {
      query: GET_INVESTMENT_INVESTMENT_FINANCING,
      result(data) {
        this.handle_get_investment_financing(data);
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors.graphQLErrors);
        return false;
      },
      update(data) {
        this.apollo_data.get_investment_financing = data;
      },
      skip: true,
      variables() {
        return {
          investment_id: this.investment_id,
        };
      },
    },
    get_all_investment_financing_types: {
      query: GET_ALL_INVESTMENT_FINANCING_TYPES,
      result(data) {
        this.handle_get_all_investment_financing_types(data);
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors.graphQLErrors);
        return false;
      },
      update(data) {
        this.apollo_data.get_all_investment_financing_types = data;
      },
    },
  },
  data() {
    return {
      finance_type_form: {
        financing_id: null,
        cash_purchase: false,
        financing_type: null,
      },
      bond_form: {
        term_years: 20,
        deposit: 0,
        interest_rate: 9.75,
        bond_id: null,
        loading: false,
      },
      monthly_repayment: {
        currency: "R",
        amount: 0,
      },
      static_values: {
        buying_price: null,
      },
      apollo_data: {
        get_investment_financing: null,
        get_all_investment_financing_types: null,
      },
      financing_types: {},
    };
  },
  methods: {
    // form handlers
    change_cash_purchase_debounced: debounce(function () {
      if (!this.finance_type_form.cash_purchase) {
        // if (this.bond_form.bond_id === null) {
        //     this.create_bond();
        // }
        this.set_financing_type("BANK_BOND");
      } else {
        this.set_financing_type("CASH");
        // if (this.bond_form.bond_id) {
        //     this.delete_bond();
        // }
      }
    }, 1000),

    delete_bond() {
      this.$apollo
        .mutate({
          mutation: DELETE_INVESTMENT_BOND,
          variables: {
            bond_id: this.bond_form.bond_id,
          },
        })
        .then((res) => {
          global_event_emitter.$emit("buying_cost_update");
          global_event_emitter.$emit("operating_cost_update");
          this.disable_get_investment_financing();
          this.bond_form.bond_id = null;
        });
    },
    create_bond() {
      this.$apollo
        .mutate({
          mutation: CREATE_INVESTMENT_BOND,
          variables: {
            financing_id: this.finance_type_form.financing_id,
          },
        })
        .then((res) => {
          global_event_emitter.$emit("buying_cost_update");
          global_event_emitter.$emit("operating_cost_update");
          this.enable_get_investment_financing();
        });
    },

    set_financing_type(type) {
      this.$apollo
        .mutate({
          mutation: UPDATE_INVESTMENT_FINANCING_TYPE,
          variables: {
            financing_id: this.finance_type_form.financing_id,
            financing_type_id: this.financing_types[type].id,
          },
        })
        .then((res) => {
          setTimeout(() => {
            global_event_emitter.$emit("buying_cost_update");
            global_event_emitter.$emit("operating_cost_update");
            this.enable_get_investment_financing();
          }, 200);
        });
    },

    input_on_key_up_debounced: debounce(function () {
      this.bond_form.loading = true;
      this.$refs.bond_form_submit_button.click();
    }, 1000),

    on_submit() {
      this.bond_form.loading = false;
      this.update_bond();
    },
    // Utils
    bond_formatted_currency(value) {
      return value.toLocaleString();
    },

    get_monthly_repayment() {
      this.monthly_repayment.amount = this.calculate_monthly_repayment();
    },
    calculate_monthly_repayment() {
      let i = this.bond_form.interest_rate / 100 / 12;
      let n = this.bond_form.term_years * 12;
      let principal = this.static_values.buying_price - this.bond_form.deposit;
      return Math.round(
        (principal * (i * Math.pow(1 + i, n))) / (Math.pow(1 + i, n) - 1)
      );
    },

    // Apollo handlers
    handle_get_all_investment_financing_types(data) {
      let flattened = graph_utils.flatten_objects_recursive(
        graph_utils.apollo_to_obj_recursive(data.data)
      );
      flattened.allInvestmentFinancingType.forEach((financing_type) => {
        this.financing_types[financing_type.name] = {
          id: financing_type.id,
          friendly_name: financing_type.friendlyName,
        };
      });
    },

    handle_get_investment_financing(data) {
      if (
        !data ||
        !data.data ||
        !data.data.investmentInvestment__financingOptions
      ) {
        return;
      }

      let flattened = graph_utils.flatten_objects_recursive(
        graph_utils.apollo_to_obj_recursive(data.data)
      );
      this.finance_type_form.financing_id =
        flattened.investmentInvestment__financingOptions[0].id;
      this.finance_type_form.financing_type =
        flattened.investmentInvestment__financingOptions[0].financingType__name;
      if (this.finance_type_form.financing_type === "BANK_BOND") {
        this.finance_type_form.cash_purchase = false;
      } else if (this.finance_type_form.financing_type === "CASH") {
        this.finance_type_form.cash_purchase = true;
      }

      this.static_values.buying_price =
        flattened.investmentInvestment__listing__buyingPrice__amount;
      if (
        flattened.investmentInvestment__financingOptions[0].bonds.length > 0
      ) {
        this.bond_form.deposit =
          flattened.investmentInvestment__financingOptions[0].bonds[0].deposit__amount;
        this.bond_form.interest_rate =
          flattened.investmentInvestment__financingOptions[0].bonds[0].projectedInterestRatePercentage;
        this.bond_form.term_years =
          flattened.investmentInvestment__financingOptions[0].bonds[0]
            .termMonths / 12;

        this.bond_form.bond_id =
          flattened.investmentInvestment__financingOptions[0].bonds[0].id;
      }
    },

    // Apollo manager
    enable_get_investment_financing() {
      this.$apollo.queries.get_investment_financing.setOptions({
        fetchPolicy: "cache-and-network",
      });
      if (!this.$apollo.queries.get_investment_financing.skip) {
        this.$apollo.queries.get_investment_financing.refetch();
      } else {
        this.$apollo.queries.get_investment_financing.skip = false;
        this.$apollo.queries.get_investment_financing.start();
      }
    },
    disable_get_investment_financing() {
      this.$apollo.queries.get_investment_financing.skip = true;
      this.$apollo.queries.get_investment_financing.stop();
    },

    update_bond() {
      this.$apollo
        .mutate({
          mutation: UPDATE_INVESTMENT_BOND,
          variables: {
            bond_id: this.bond_form.bond_id,
            projected_interest_rate: parseFloat(this.bond_form.interest_rate),
            monthly_repayment_amount: parseFloat(this.monthly_repayment.amount),
            deposit_amount: parseFloat(this.bond_form.deposit),
            term_months: parseInt(this.bond_form.term_years * 12),
          },
        })
        .then((res) => {
          global_event_emitter.$emit("buying_cost_update");
          global_event_emitter.$emit("operating_cost_update");
        });
    },
  },
  mounted() {
    this.get_monthly_repayment();

    // Apollo management
    if (this.investment_id !== "") {
      this.enable_get_investment_financing();
    } else {
      this.disable_get_investment_financing();
    }
  },
  watch: {
    // Prop watchers
    ivestment_id() {
      if (this.investment_id !== "") {
        this.enable_get_investment_financing();
      } else {
        this.disable_get_investment_financing();
      }
    },

    // bond_form watchers
    "bond_form.interest_rate"() {
      this.get_monthly_repayment();
    },
    "static_values.buying_price"() {
      this.get_monthly_repayment();
    },
    "bond_form.deposit"() {
      this.get_monthly_repayment();
    },
    "bond_form.term_years"() {
      this.get_monthly_repayment();
    },
  },
};
</script>

<style></style>
