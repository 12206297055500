<template>
  <div>
    <div class="header pb-6 d-flex align-items-center">
      <span class="mask bg-white opacity-8"></span>
      <b-container fluid class="d-flex align-items-center">
        <b-row>
          <b-col lg="12">
            <h1 class="display-1 text-orange pb-0 mb-0">
              {{ userTitle ? `Hello, ${userTitle}!` : "Welcome!" }}
            </h1>
            <p class="text-default bold mt-0 mb-5 font-weight-bold">
              Let's take the guesswork out of real estate investing
            </p>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <b-container class="mt--6">
      <b-row class="mb-4">
        <b-col sm="3">
          <b-form
            class="navbar-search form-inline mr-sm-3 navbar-search-light"
            @submit.prevent="onSearchSubmit"
          >
            <b-form-group class="mb-0">
              <b-input-group class="input-group-alternative input-group-merge">
                <div class="input-group-prepend">
                  <span class="input-group-text"
                    ><i class="fas fa-search"></i
                  ></span>
                </div>
                <b-form-input
                  placeholder="Search"
                  type="text"
                  v-model="searchTerm"
                  @keyup="onKeyUpSearch"
                />
                <input type="submit" ref="searchFormSubmit" hidden />
              </b-input-group>
            </b-form-group>
            <button type="button" class="close" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </b-form>
        </b-col>
        <b-col>
          <transition>
            <i
              v-if="isSearchLoading"
              class="fas fa-spinner fa-spin ml--3 mt-3 text-muted"
            ></i>
          </transition>
        </b-col>
      </b-row>
      <b-row>
        <discover-listing-feature-card
          v-for="listing in propertyListings"
          :key="listing.id"
          v-bind="listing"
        />
        <b-col sm="12">
          <h1 class="text-center">
            <i v-if="isLoading" class="fas fa-spinner fa-spin"></i>
          </h1>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { debounce } from "debounce";
import DiscoverListingFeatureCard from "@/views/Components/Listing/DiscoverListingFeatureCard.vue";
import {
  GET_ALL_PROPERTY_LISTINGS_STATUS_FILTER,
  GET_ALL_PROPERTY_LISTING_SATUSES,
} from "@/graphql/queries";

export default {
  name: "DiscoverListings",
  components: { DiscoverListingFeatureCard },
  data() {
    return {
      user: {},
      searchTerm: "",
      isSearchLoading: false,
      isLoading: false,
      propertyListings: [],
      listingStatusIds: [],
      offset: 0,
      allListingsLoaded: false,
    };
  },
  apollo: {
    allPropertyListingStatus: {
      query: GET_ALL_PROPERTY_LISTING_SATUSES,
      result({ data }) {
        let flattened = graph_utils.flatten_relay_response(
          data.allPropertyListingStatus
        );
        this.listingStatusIds = [];
        let supportedStatuses = ["PUBLISHED", "SOLD", "UNDER_OFFER"];
        flattened.forEach((element) => {
          if (supportedStatuses.includes(element.name)) {
            this.listingStatusIds.push(element.id);
          }
        });
        this.fetchListings();
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name);
        console.log(errors.graphQLErrors);
        return false;
      },
    },
  },
  methods: {
    loadUserData() {
      this.user = utils.deepcopy(this.$store.getters.getUser);
    },
    fetchListings(offset = 0) {
      if (this.allListingsLoaded) {
        return;
      }
      this.isLoading = true;

      const observableQuery = this.$apollo.watchQuery({
        query: GET_ALL_PROPERTY_LISTINGS_STATUS_FILTER,
        variables: {
          listing_status_ids: this.listingStatusIds,
          first: 25,
          offset: offset,
          search: this.searchTerm || "",
        },
        fetchPolicy: "cache-and-network",
      });

      const subscription = observableQuery.subscribe({
        next: ({ data, networkStatus }) => {
          if (!data || !data.allPropertyListing) {
            this.isLoading = false;
            return;
          }
          const newListings = this.handleListings(
            data.allPropertyListing,
            offset
          );

          if (newListings.length < 25) {
            this.allListingsLoaded = true;
          }

          this.isLoading = false;
        },
        error: (error) => {
          console.error("Error fetching listings:", error);
          this.isLoading = false;
        },
      });

      this.$on("hook:beforeDestroy", () => {
        subscription.unsubscribe();
      });
    },
    handleListings(listings, offset) {
      if (!listings) return [];
      const newListings = graph_utils
        .flatten_relay_response(listings)
        .map((listing) => ({
          id: listing.id,
          name: listing.name,
          address: { city: listing.address__cityName, suburb: "" },
          buying_price: {
            symbol: listing.buyingPrice__currency_symbol || "R",
            amount: listing.buyingPrice__amount,
          },
          cashflow: {
            symbol: listing.totalExpectedRentalIncome__currency_symbol || "R",
            amount: listing.totalExpectedRentalIncome__amount,
          },
          bathrooms: listing.subunits[0]?.bathroomCount || 0,
          bedrooms: listing.subunits[0]?.bedroomCount || 0,
          tags: [],
          type: listing.listingType__name,
          image_url: listing.artifacts[0]?.file || "",
          status: {
            name: listing.status__name,
            friendly_name: listing.status__friendlyName,
          },
        }));

      if (offset === 0) {
        this.propertyListings = newListings;
        console.log("Listings: ", this.propertyListings);
      } else {
        this.propertyListings = [...this.propertyListings, ...newListings];
      }
      this.offset = offset;
      return newListings;
    },
    onKeyUpSearch: debounce(function () {
      this.isSearchLoading = true;
      this.$refs.searchFormSubmit.click();
    }, 1000),
    onSearchSubmit() {
      this.allListingsLoaded = false;
      this.offset = 0;
      this.fetchListings();
      this.isSearchLoading = false;
    },
    onScroll() {
      const scrollPosition = window.scrollY + window.innerHeight;
      const threshold = document.documentElement.scrollHeight * 0.75;

      if (
        scrollPosition >= threshold &&
        !this.isLoading &&
        !this.allListingsLoaded
      ) {
        this.fetchListings(this.offset + 25);
      }
    },
    capitalize(string) {
      return typeof string === "string"
        ? string.charAt(0).toUpperCase() + string.slice(1)
        : "";
    },
  },
  computed: {
    userTitle() {
      return this.user.first_name
        ? this.capitalize(this.user.first_name)
        : null;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
    this.loadUserData();
    this.$root.$on("profile_update", () => setTimeout(this.loadUserData, 500));
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
    this.$root.$off("profile_update");
  },
};
</script>

<style>
.profile-header {
  background-image: url(/img/theme/profile-cover.jpg);
  background-size: cover;
  background-position: center top;
  min-height: 500px;
}

.enter-active,
.leave-active {
  transition: opacity 0.15s;
}

.enter,
.leave-to {
  opacity: 0;
}
</style>
