<template>
  <div>
    <b-row v-if="$apollo.loading">
      <b-col sm="12" md="12" lg="12" class="text-center">
        <Transition> <i class="fas fa-spinner fa-spin"></i> </Transition></b-col
    ></b-row>
    <b-row v-if="!$apollo.loading">
      <b-col sm="12" md="12" lg="12">
        <listing-capital-investment
          :buying_price="buying_price"
          :transaction_costs="transaction_costs"
          :modifications="modifications"
        ></listing-capital-investment>

        <listing-monthly-pnl
          :total_rental_income="{
            amount: total_rental_income,
            symbol: 'R',
          }"
          :recurring_expenses="recurring_expenses"
          :buying_price="buying_price"
          :total_buying_costs="total_buying_costs"
          :total_rental_months="total_rental_months"
        ></listing-monthly-pnl>

        <listing-rental-income
          :subunits="subunits"
          :total_rental_income="{
            amount: total_rental_income,
            symbol: 'R',
          }"
        ></listing-rental-income>
      </b-col>
    </b-row>
  </div>
</template>
<script>
// Queries
import { GET_LISTING_INVESTMENT_FINANCING } from "@/graphql/queries";
import { GET_PROPERTY_MODIFICATIONS_LISTING } from "@/graphql/queries";

//Custom Components
import ListingCapitalInvestment from "@/views/Components/Listing/Finances/ListingCapitalInvestment.vue";
import ListingRentalIncome from "@/views/Components/Listing/Finances/ListingRentalIncome.vue";
import ListingMonthlyPnl from "@/views/Components/Listing/Finances/ListingMonthlyPnl.vue";

export default {
  name: "ListingFinances",
  components: {
    ListingCapitalInvestment,
    ListingRentalIncome,
    ListingMonthlyPnl,
  },

  props: {
    listing_id: {
      type: String,
      description: "ID of the listing in question",
      default: "",
    },
  },
  apollo: {
    get_listing_investment_financing: {
      query: GET_LISTING_INVESTMENT_FINANCING,
      result(data) {
        this.handle_get_listing_investment_financing(data);
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors.graphQLErrors);
        return false;
      },
      update(data) {
        this.apollo_data.get_listing_investment_financing = data;
      },
      skip: true,
      variables() {
        return {
          listing_id: this.listing_id,
        };
      },
    },
    get_property_modifications_listing: {
      query: GET_PROPERTY_MODIFICATIONS_LISTING,
      result(data) {
        this.handle_get_property_modifications_listing(data);
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors.graphQLErrors);
        return false;
      },
      update(data) {
        this.apollo_data.get_property_modifications_listing = data;
      },
      skip: true,
      variables() {
        return {
          listing_id: this.listing_id,
        };
      },
    },
  },
  data() {
    return {
      apollo_data: {
        get_listing_investment_financing: null,
        get_property_modifications_listing: null,
      },
      buying_price: { amount: 0, symbol: "" },
      transaction_costs: [],
      recurring_expenses: [],
      subunits: [],
      total_rental_income: 0,
      total_rental_months: 12,
      total_buying_costs: {
        amount: null,
        symbol: "R",
      },
      modifications: [],
    };
  },
  methods: {
    // Apollo Handlers

    handle_get_property_modifications_listing(data) {
      if (!data || !data.data) {
        return;
      }
      let flattened = graph_utils.flatten_objects_recursive(
        graph_utils.apollo_to_obj_recursive(data.data)
      );
      this.modifications = flattened.allPropertyModification;
      this.calculate_total_buying_costs();
    },

    handle_get_listing_investment_financing(data) {
      if (!data || !data.data) {
        return;
      }
      let flattened = graph_utils.flatten_objects_recursive(
        graph_utils.apollo_to_obj_recursive(data.data)
      );

      if (flattened.propertyListing__investmentSet < 1) {
        return;
      }
      this.transaction_costs =
        flattened.propertyListing__investmentSet[0].transactioncostSet.reverse();
      this.recurring_expenses =
        flattened.propertyListing__investmentSet[0].recurringexpenseSet;
      this.buying_price = {
        amount: flattened.propertyListing__buyingPrice__amount,
        symbol: flattened.propertyListing__buyingPrice__currency__symbol,
      };
      this.subunits = flattened.propertyListing__subunits;

      // Check if subunits are wrapped in an observer
      if (this.subunits && this.subunits.__ob__) {
        // Access the underlying value of the observer
        this.subunits = this.subunits.__ob__.value;
      }

      // Check if subunits is an array and has elements
      if (Array.isArray(this.subunits) && this.subunits.length > 0) {
        this.calculate_total_rental_income(this.subunits);
        this.total_rental_months = this.subunits[0].futureRentalApproachMonths;
      }

      this.calculate_total_buying_costs();
    },

    // Apollo management
    enable_get_listing_investment_financing() {
      this.$apollo.queries.get_listing_investment_financing.setOptions({
        fetchPolicy: "cache-and-network",
      });
      if (!this.$apollo.queries.get_listing_investment_financing.skip) {
        this.$apollo.queries.get_listing_investment_financing.refetch();
      } else {
        this.$apollo.queries.get_listing_investment_financing.skip = false;
        this.$apollo.queries.get_listing_investment_financing.start();
      }
    },
    enable_get_property_modifications_listing() {
      this.$apollo.queries.get_property_modifications_listing.setOptions({
        fetchPolicy: "cache-and-network",
      });
      if (!this.$apollo.queries.get_property_modifications_listing.skip) {
        this.$apollo.queries.get_property_modifications_listing.refetch();
      } else {
        this.$apollo.queries.get_property_modifications_listing.skip = false;
        this.$apollo.queries.get_property_modifications_listing.start();
      }
    },

    calculate_total_rental_income(subunits) {
      let total_rental_income = 0;
      if (subunits.length > 0) {
        subunits.forEach((subunit) => {
          if (subunit.children__totalCount == 0) {
            total_rental_income += subunit.expectedRentalIncome__amount;
          }
        });
      }
      this.total_rental_income = total_rental_income;
    },

    calculate_total_buying_costs() {
      let total_buying_costs = 0;
      this.modifications.forEach((element) => {
        total_buying_costs += element.expectedCost__amount;
      });

      this.transaction_costs.forEach((element) => {
        total_buying_costs += element.cost__amount;
      });

      this.total_buying_costs.amount = total_buying_costs;
    },
  },
  watch: {
    // Prop watchers
    listing_id() {
      if (this.investment_id != "") {
        this.enable_get_listing_investment_financing();
        this.enable_get_property_modifications_listing();
      }
    },
  },
  mounted() {
    if (this.investment_id != "") {
      this.enable_get_listing_investment_financing();
      this.enable_get_property_modifications_listing();
    }
  },
};
</script>
<style></style>
