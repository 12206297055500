<template>
    <div>
        <b-modal
            v-model="modal.show"
            size="md"
            id="cru-modal"
            :title="expense_id === '' ? 'Create Expense' : 'Edit Expense'"
            ok-title="Save"
            @hidden="handle_modal_hide"
            @ok="handle_ok"
            class="p-0"
        >
            <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
                <b-form @submit.prevent="handleSubmit(on_submit)">
                    <input
                        type="submit"
                        ref="expense_form_submit_button"
                        hidden
                    />
                    <b-row>
                        <b-col lg="6">
                            <base-input
                                label="Cost Name*"
                                class="mb-3"
                                placeholder="Enter a name"
                                name="Cost Name"
                                :rules="{ required: true }"
                                v-model="form.cost_name"
                                :disabled="$apollo.loading && !form.create_mode"
                                @keyup="input_on_key_up_debounced"
                            >
                            </base-input>
                        </b-col>
                        <b-col lg="6"> </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="6">
                            <base-input
                                label="Cost Amount (R)*"
                                class="mb-3"
                                type="number"
                                placeholder="Enter an amount"
                                name="Cost Amount"
                                :rules="{ required: true }"
                                v-model="form.cost_amount"
                                :disabled="$apollo.loading && !form.create_mode"
                                @keyup="input_on_key_up_debounced"
                            >
                            </base-input>
                        </b-col>
                        <b-col lg="6"> </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
            <template #modal-footer="{ ok }">
                <b-button
                    variant="default"
                    @click="ok()"
                    :disabled="modal.loading || $apollo.loading"
                >
                    <i
                        v-if="modal.loading || $apollo.loading"
                        class="fas fa-spinner fa-spin"
                    ></i>
                    Save
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>
// Modules
import { debounce } from "debounce";

//Custom Components

// Queries
import { GET_INVESTMENT_RECURRING_EXPENSE } from "@/graphql/queries";

// Mutations
import { CREATE_INVESTMENT_RECURRING_EXPENSE } from "@/graphql/mutations";
import { UPDATE_INVESTMENT_RECURRING_EXPENSE } from "@/graphql/mutations";

export default {
    name: "CruOperatingCost",
    components: {},
    emits: ["expense_changed"],
    props: {
        expense_id: {
            type: String,
            description: "The id of the recurring expense to be edited.",
            default: "",
        },
        investment_id: {
            type: String,
            description: "The id of the investment that this cost belongs to.",
            default: "",
        },
        show: {
            type: Boolean,
            description: "Weather to show the modal.",
            default: false,
        },
    },
    apollo: {
        get_recurring_expense: {
            query: GET_INVESTMENT_RECURRING_EXPENSE,
            result(data) {
                this.handle_get_recurring_expense(data);
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors);
                return false;
            },
            update(data) {
                this.apollo_data.get_recurring_expense = data;
            },
            variables() {
                return {
                    expense_id: this.expense_id,
                };
            },
            skip: true,
        },
    },

    data() {
        return {
            modal: { show: false, loading: false },
            apollo_data: {
                get_recurring_expense: null,
            },
            form: { cost_name: null, cost_amount: null },
        };
    },
    methods: {
        // Modal Handlers
        handle_ok(bvModalEvent) {
            bvModalEvent.preventDefault();
            if (!this.$apollo.loading) {
                this.$refs.expense_form_submit_button.click();
            }
            this.modal.loading = true;
            setTimeout(() => {
                this.modal.show = false;
                this.modal.loading = false;
            }, 1000);
        },
        handle_modal_hide() {
            setTimeout(() => {
                this.form = { cost_name: null, cost_amount: null };
            }, 100);
        },

        // Form handlers
        input_on_key_up_debounced: debounce(function () {
            if (this.expense_id != "") {
                this.$refs.expense_form_submit_button.click();
            }
            
        }, 1000),

        on_submit() {
            if (this.expense_id == "") {
                this.create_recurring_espense();
            } else {
                this.update_recurring_expense();
            }
        },
        update_recurring_expense() {
            this.$apollo
                .mutate({
                    mutation: UPDATE_INVESTMENT_RECURRING_EXPENSE,
                    variables: {
                        currency_identifer: "ZAR",
                        amount: this.form.cost_amount,
                        description: this.form.cost_name,
                        investment_id: this.investment_id,
                        expense_id: this.expense_id,
                    },
                })
                .then((res) => {
                    this.$emit("expense_changed", this.expense_id);
                });
        },
        create_recurring_espense() {
            this.$apollo
                .mutate({
                    mutation: CREATE_INVESTMENT_RECURRING_EXPENSE,
                    variables: {
                        currency_identifer: "ZAR",
                        amount: this.form.cost_amount,
                        description: this.form.cost_name,
                        investment_id: this.investment_id,
                    },
                })
                .then((res) => {
                    let new_expense_id = "";
                    if (
                        "investmentCreateRecurringExpense" in res.data &&
                        "recurringExpense" in
                            res.data.investmentCreateRecurringExpense
                    ) {
                        new_expense_id =
                            res.data.investmentCreateRecurringExpense
                                .recurringExpense.id;
                    }
                    this.$emit("expense_changed", new_expense_id);
                });
        },

        // Utils
        formatted_currency(value) {
            return value.toLocaleString();
        },

        // Apollo Managers
        enable_get_recurring_expense() {
            this.$apollo.queries.get_recurring_expense.setOptions({
                fetchPolicy: "network-only",
            });
            if (!this.$apollo.queries.get_recurring_expense.skip) {
                this.$apollo.queries.get_recurring_expense.refetch();
            } else {
                this.$apollo.queries.get_recurring_expense.skip = false;
                this.$apollo.queries.get_recurring_expense.start();
            }
            if (!this.$apollo.queries.get_recurring_expense.skip) {
                this.$apollo.queries.get_recurring_expense.refetch();
            } else {
                this.$apollo.queries.get_recurring_expense.skip = false;
                this.$apollo.queries.get_recurring_expense.start();
            }
        },

        // Apollo handlers
        handle_get_recurring_expense(data) {
            this.form.cost_name =
                data.data.investmentRecurringExpense.description;
            this.form.cost_amount =
                data.data.investmentRecurringExpense.cost.amount;
        },
    },
    watch: {
        expense_id(new_expense_id) {
            if (new_expense_id !== "") {
                this.enable_get_recurring_expense();
            } else {
                this.$apollo.queries.get_recurring_expense.skip = true;
                this.$apollo.queries.get_recurring_expense.stop();
            }
        },
        show(new_show) {
            this.modal.show = new_show;
            if (this.expense_id !== "") {
                this.enable_get_recurring_expense();
            }
        },
    },
    mounted() {
        if (this.expense_id !== "") {
            this.enable_get_recurring_expense();
        }
        this.modal.show = this.show;
    },
};
</script>
<style></style>
