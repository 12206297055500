<template>
  <div>
    <div class="header pb-6 align-items-center">
      <!-- Mask -->
      <span class="mask bg-white opacity-8"></span>
      <!-- Header container -->
      <b-container fluid class="align-items-center">
        <b-row>
          <b-col class="mt-4">
            <div
              @click="$router.back()"
              class="icon icon-shape rounded-circle shadow bg-default text-white"
            >
              <i class="fa fa-arrow-left"></i>
            </div>
          </b-col>
          <b-col lg="11">
            <h1 class="display-1 text-orange pb-0 mb-0">Manage Listing</h1>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <b-container class="mt--6">
      <b-row>
        <b-col sm="12" v-if="$apollo.loading">
          <h1 class="text-center">
            <i class="fas fa-spinner fa-spin"></i>
          </h1>
        </b-col>
        <!-- Main view -->
        <b-col sm="12" md="8" v-else>
          <b-row>
            <b-col>
              <b-card>
                <b-card-body class="p-0">
                  <!-- Name -->
                  <b-row class="pb-0">
                    <b-col>
                      <h1 class="display-2 mb-0 font-weight-bold">
                        {{ listing.name }}
                      </h1>
                    </b-col>
                  </b-row>
                  <!-- Listing Status -->

                  <b-row>
                    <b-col>
                      <update-listing-status-comp
                        :listing_id="listing.id"
                      ></update-listing-status-comp>
                    </b-col>
                  </b-row>
                  <!-- Type -->
                  <b-row v-if="listing.type">
                    <b-col>
                      <b-row>
                        <b-col>
                          <small class="text-muted">
                            {{ listing.type }}
                          </small>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <!-- Location -->
                  <b-row class="pb-2" v-if="listing.address.city">
                    <b-col>
                      <small class="text-muted">
                        <i class="ni ni-pin-3"></i>
                      </small>
                      <small class="text-muted" v-if="listing.address.suburb">
                        <i class="ni ni-pin-3"></i>
                        {{ listing.address.suburb }},
                      </small>
                      <small class="text-muted">
                        {{ listing.address.city }}
                      </small>
                    </b-col>
                  </b-row>
                  <!-- Images -->
                  <b-row class="mt-2">
                    <b-col>
                      <b-row>
                        <b-col v-if="listing.images.length > 0">
                          <b-img-lazy
                            left
                            :src="listing.images[0].file"
                            rounded
                            fluid-grow
                          ></b-img-lazy>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col class="mt-5">
                      <div>
                        <div class="images" v-viewer>
                          <b-img
                            v-for="(image, index) in images_bottom"
                            :key="image.id"
                            left
                            :src="image.file"
                            width="130px"
                            rounded
                            class="p-1"
                            :hidden="index > 5 || index == 0"
                          />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <!-- Description -->
                  <b-row class="mt-4">
                    <b-col>
                      <h2 class="text-orange">Description</h2>
                      <div v-html="listing.description"></div>
                      <!-- <p>
                                                Property Description XXXXXX Lorem ipsum
                                                dolor sit amet, consectetuer adipiscing
                                                elit, sed diam nonummy nibh euismod
                                                tincidunt ut laoreet dolore magna
                                                aliquam erat volutpat. Ut wisi enim ad
                                                minim veniam, quis nostrud exerci tation
                                                ullamcorper suscipit lobortis nisl ut
                                                aliquip ex ea commodo consequat. Duis
                                                autem vel eum iriure dolor in hendrerit
                                                in
                                            </p> -->
                    </b-col>
                  </b-row>
                  <!-- Features -->
                  <b-row class="mt-4">
                    <b-col>
                      <h2 class="text-orange">Features</h2>
                      <!-- Bed & Bath -->
                      <b-row>
                        <b-col
                          sm="2"
                          md="5"
                          class=""
                          v-if="main_subunit.bedroomCount > 0"
                        >
                          <small class="text-default">
                            {{
                              show_rounded_counts(main_subunit.bedroomCount)
                            }}&nbsp;
                            <i class="fa fa-bed"></i>
                            Bedrooms
                          </small>
                        </b-col>
                        <b-col
                          sm="2"
                          md="5"
                          class=""
                          v-if="main_subunit.bathroomCount > 0"
                        >
                          <small class="text-default">
                            {{
                              show_rounded_counts(main_subunit.bathroomCount)
                            }}
                            &nbsp;
                            <i class="fa fa-bath"></i>
                            Bathrooms
                          </small>
                        </b-col>
                        <b-col sm="2" md="5" class="" v-if="listing.size">
                          <small class="text-default">
                            {{ listing.size }}&#13217;
                            <i class="fa fa-home"></i>
                            Space
                          </small>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <!-- Amenities -->
                  <b-row class="mt-4">
                    <b-col>
                      <h2 class="text-orange">Amenities</h2>
                      <div v-html="listing.amenities"></div>
                      <!-- <p>
                                                Amenities XXXXXX consectetuer adipiscing
                                                elit, sed diam nonummy nibh euismod
                                                tincidunt ut laoreet dolore magna
                                                aliquam erat volutpat. Ut wisi enim ad
                                                minim veniam, quis nostrud exerci tation
                                                ullamcorper suscipit lobortis nisl ut
                                                aliquip ex ea commodo consequat. Duis
                                                autem vel eum iriure dolor in hendrerit
                                                in
                                            </p> -->
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
          <b-row v-if="!$apollo.loading">
            <b-col>
              <listing-finances :listing_id="listing.id"></listing-finances>
            </b-col>
          </b-row>
        </b-col>
        <!-- Sidebar -->
        <b-col class="order-sm-1" sm="12" md="4" v-if="!$apollo.loading">
          <b-row>
            <b-col>
              <b-card>
                <b-card-body class="p-0">
                  <!-- Expected Cashflow -->
                  <b-row>
                    <b-col>
                      <b-row>
                        <b-col>
                          <small class="font-weight-bold m-0">
                            Rental Income*
                          </small>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <h1 class="font-weight-bold m-0 text-primary">
                            {{
                              listing.total_expected_rental_income.currency
                                .symbol
                            }}{{
                              formatted_currency(
                                listing.total_expected_rental_income.amount
                              )
                            }}
                          </h1>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <!-- Negotiated Price -->
                  <b-row>
                    <b-col>
                      <b-row>
                        <b-col>
                          <small class="font-weight-bold m-0">
                            Negotiated Price*
                          </small>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <h4
                            class="font-weight-bold m-0 text-primary"
                            v-if="
                              listing.market_price.amount >
                              listing.buying_price.amount
                            "
                          >
                            <del>
                              {{ listing.market_price.currency.symbol
                              }}{{
                                formatted_currency(listing.market_price.amount)
                              }}
                            </del>
                          </h4>
                          <h1 class="font-weight-bold m-0 text-default">
                            {{ listing.buying_price.currency.symbol
                            }}{{
                              formatted_currency(listing.buying_price.amount)
                            }}
                          </h1>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <approval-component
                :approval_id="listing.approval.id"
              ></approval-component>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Vue from "vue";
Vue.use(VueViewer);
// Components
import ListingFinances from "@/views/Components/Listing/Finances/ListingFinances.vue";
import ApprovalComponent from "@/views/Components/Approval/ApprovalComponent.vue";
import UpdateListingStatusComp from "@/views/Components/Listing/UpdateListingStatusComp";

// Queries
import { GET_PROPERTY_LISTING_VIEW_BASIC } from "@/graphql/queries";
import { GET_PROPERTY_LISTING_ARTIFACTS } from "@/graphql/queries";
import { GET_USER_ACCOUNT_AGREEMENTS } from "@/graphql/queries";
import { GET_PROPERTY_SUBUNIT_FULL_DETAIL } from "@/graphql/queries";
import { GET_PROPERTY_LISTING_SUBUNITS_LIGHT } from "@/graphql/queries";

export default {
  name: "ViewListing",
  components: {
    ListingFinances,
    ApprovalComponent,
    UpdateListingStatusComp,
  },
  props: {
    listing_id: {
      type: String,
      description: "Listing ID",
      default: null,
    },
  },
  apollo: {
    get_property_listing_view: {
      query: GET_PROPERTY_LISTING_VIEW_BASIC,
      result(data) {
        this.handle_get_property_listing_view(data);
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors);
        return false;
      },
      update(data) {
        this.apollo_data.get_property_listing_view = data;
      },
      variables() {
        return {
          listing_id: this.listing.id,
        };
      },
      skip: true,
    },

    get_user_account_agrements: {
      query: GET_USER_ACCOUNT_AGREEMENTS,
      result(data) {
        this.handle_get_user_account_agrements(data);
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors);
        return false;
      },
      update(data) {
        this.apollo_data.get_user_account_agrements = data;
      },
      variables() {
        return {
          account_id: this.$store.getters.getAccountIdB64,
        };
      },
    },
    get_property_listing_artifacts: {
      query: GET_PROPERTY_LISTING_ARTIFACTS,
      result(data) {
        this.handle_get_property_listing_artifacts(data);
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors);
        return false;
      },
      update(data) {
        this.apollo_data.get_property_listing_artifacts = data;
      },
      variables() {
        return {
          listing_id: this.listing.id,
        };
      },
    },
    get_main_subunit_id: {
      query: GET_PROPERTY_LISTING_SUBUNITS_LIGHT,
      result(data) {
        this.handle_get_main_subunit_id(data);
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors.graphQLErrors);
        return false;
      },
      update(data) {
        this.apollo_data.get_main_subunit_id = data;
      },
      variables() {
        return {
          listing_id: this.listing.id,
        };
      },
      skip: true,
    },
    get_subunit_full_detail: {
      query: GET_PROPERTY_SUBUNIT_FULL_DETAIL,
      result(data) {
        this.handle_get_subunit_full_detail(data);
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors.graphQLErrors);
        return false;
      },
      update(data) {
        this.apollo_data.get_subunit_full_detail = data;
      },
      variables() {
        return {
          subunit_id: this.main_subunit_id,
        };
      },
      skip: true,
    },
  },
  data() {
    return {
      pts_signed: false,
      main_subunit_id: "",
      main_subunit: {
        bathroomCount: 0,
        bedroomCount: 0,
      },
      apollo_data: {
        get_property_listing_view: "",
      },
      preview: false,
      listing: {
        id: "",
        name: "",
        location: "",
        type: "",
        amenities: "",
        description: "",
        images: [],
        images_test: [],
        address: {
          suburb: "",
          city: "",
        },
        total_expected_rental_income: {
          amount: 0,
          currency: {
            symbol: "R",
          },
        },
        buying_price: {
          amount: 0,
          currency: {
            symbol: "R",
          },
        },
        market_price: {
          amount: 0,
          currency: {
            symbol: "R",
          },
        },
        bedrooms: 0,
        bathrooms: 0,
        carports: 1,
        status: "",
        tags: [],
        approval: {
          id: null,
        },
      },
    };
  },
  methods: {
    formatted_currency(value) {
      return value.toLocaleString();
    },

    get_listing_id() {
      this.listing.id = this.$route.params.listing_id;
      this.enable_get_property_listing_view();
    },
    get_preview_mode() {
      this.preview = this.$route.query.preview;
    },

    // Apollo Handlers
    handle_get_main_subunit_id(data) {
      let flattened = graph_utils.flatten_objects_recursive(
        graph_utils.apollo_to_obj_recursive(data.data)
      );
      for (let i = 0; i < flattened.allPropertySubunit.length; i++) {
        if (flattened.allPropertySubunit[i].subunitType__name == "MAIN") {
          this.main_subunit_id = flattened.allPropertySubunit[i].id;
        }
      }
    },
    handle_get_subunit_full_detail(data) {
      this.main_subunit = data.data.propertySubunit;
    },

    handle_get_user_account_agrements(data) {
      if (data.data.userAccountAccount.executionSet.totalCount > 0) {
        data.data.userAccountAccount.executionSet.edges.forEach((item) => {
          if (item.node.contract.name == "PROPOSAL_TO_SERVICE") {
            this.pts_signed = true;
          }
        });
      }
    },

    handle_get_property_listing_artifacts(data) {
      if ("data" in data) {
        let flattened = graph_utils.flatten_objects_recursive(
          graph_utils.apollo_to_obj_recursive(data.data)
        );
        this.listing.images = flattened.allPropertyArtifact;
        if (flattened.allPropertyArtifact.length > 0) {
          this.images_test = [];
          flattened.allPropertyArtifact.forEach((element) => {
            this.images_test.push(element.file);
          });
        }
      }
    },
    handle_get_property_listing_view(data) {
      if (!data || !data.data || !data.data.propertyListing) {
        return;
      }
      let listing = data.data.propertyListing;
      this.listing.name = listing.name;
      this.listing.description = listing.description;
      this.listing.amenities = listing.amenities;
      this.listing.address.suburb = listing.address.suburbName;
      this.listing.address.city = listing.address.cityName;
      this.listing.type = listing.listingType.friendlyName;
      this.listing.status = listing.status;
      this.listing.total_expected_rental_income =
        listing.totalExpectedRentalIncome;
      this.listing.buying_price = listing.buyingPrice;
      this.listing.market_price = listing.marketPrice;

      if (listing.status.name != "PUBLISHED") {
        this.listing.tags = [{ text: "Unpublished", type: "danger" }].concat(
          this.listing.tags
        );
      }
      this.listing.approval.id = listing.approval.id;
    },

    // Apollo triggers
    enable_get_property_listing_view() {
      this.$apollo.queries.get_property_listing_view.setOptions({
        fetchPolicy: "cache-and-network",
      });
      if (!this.$apollo.queries.get_property_listing_view.skip) {
        this.$apollo.queries.get_property_listing_view.refetch();
      } else {
        this.$apollo.queries.get_property_listing_view.skip = false;
        this.$apollo.queries.get_property_listing_view.start();
      }
    },
    enable_get_property_listing_interest() {
      this.$apollo.queries.get_property_listing_interest.setOptions({
        fetchPolicy: "cache-and-network",
      });
      if (!this.$apollo.queries.get_property_listing_interest.skip) {
        this.$apollo.queries.get_property_listing_interest.refetch();
      } else {
        this.$apollo.queries.get_property_listing_interest.skip = false;
        this.$apollo.queries.get_property_listing_interest.start();
      }
    },
    enable_get_main_subunit_id() {
      if (!this.$apollo.queries.get_main_subunit_id.skip) {
        this.$apollo.queries.get_main_subunit_id.refetch();
      } else {
        this.$apollo.queries.get_main_subunit_id.skip = false;
        this.$apollo.queries.get_main_subunit_id.start();
      }
      if (!this.$apollo.queries.get_main_subunit_id.skip) {
        this.$apollo.queries.get_main_subunit_id.refetch();
      } else {
        this.$apollo.queries.get_main_subunit_id.skip = false;
        this.$apollo.queries.get_main_subunit_id.start();
      }
    },
    enable_get_subunit_full_detail() {
      if (!this.$apollo.queries.get_subunit_full_detail.skip) {
        this.$apollo.queries.get_subunit_full_detail.refetch();
      } else {
        this.$apollo.queries.get_subunit_full_detail.skip = false;
        this.$apollo.queries.get_subunit_full_detail.start();
      }
      if (!this.$apollo.queries.get_subunit_full_detail.skip) {
        this.$apollo.queries.get_subunit_full_detail.refetch();
      } else {
        this.$apollo.queries.get_subunit_full_detail.skip = false;
        this.$apollo.queries.get_subunit_full_detail.start();
      }
    },

    // Mutations

    //Utils
    show_rounded_counts(value) {
      return (Math.round(value * 100) / 100).toFixed(1);
    },
  },
  mounted() {
    this.get_listing_id();
    this.get_preview_mode();

    // Refetching data if applicable
  },
  watch: {
    "listing.id"() {
      if (this.listing.id != "") {
        this.enable_get_main_subunit_id();
      }
    },
    main_subunit_id() {
      if (this.main_subunit_id !== null) {
        this.enable_get_subunit_full_detail();
      }
    },
  },
  created() {},
  computed: {
    images_bottom() {
      if (this.listing.images.length > 0) {
        return this.listing.images.slice(1);
      } else {
        return [];
      }
    },
  },
};
</script>
<style>
.profile-header {
  background-image: url(/img/theme/profile-cover.jpg);
  background-size: cover;
  background-position: center top;
  min-height: 500px;
}
</style>
