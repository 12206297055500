<template>
    <div>
        <!-- Modals -->
        <crud-rating-modal
            v-model="modals.crud_rating.show"
            :rating_id="rating_id"
            :rating_submission_id="user_rating_submission_id"
            @created="handle_created_rating_submission"
        ></crud-rating-modal>
        <!-- Body -->
        <b-card>
            <b-card-body class="p-0">
                <b-row>
                    <b-col
                        ><div id="rating-container" class="rating-item-list">
                            <div
                                v-for="rating in ratings"
                                :key="rating.id"
                                name="rating"
                            >
                                <rating-view-item
                                    :title="rating.title"
                                    :comment="rating.comment"
                                    :rating="rating.stars"
                                    :date="rating.date"
                                    v-if="rating.stars > 0"
                                >
                                </rating-view-item>
                                <hr
                                    class="mb-3 mt-1 mx-1"
                                    v-if="
                                        ratings.length > 0 && rating.stars > 0
                                    "
                                />
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-4" v-if="ratings.length > 0">
                    <b-col class="text-center" v-if="user_rating_submission_id">
                        <base-button
                            type="primary"
                            :pill="true"
                            @click="show_crud_modal()"
                        >
                            Update Rating
                        </base-button>
                    </b-col>
                    <b-col class="text-center" v-else>
                        <base-button
                            type="primary"
                            :pill="true"
                            @click="show_crud_modal()"
                        >
                            Rate
                        </base-button>
                    </b-col>
                </b-row>
                <b-row v-else>
                    <b-col class="text-center">
                        <base-button
                            type="primary"
                            :pill="true"
                            @click="show_crud_modal()"
                        >
                            Create a rating
                        </base-button>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
// Queries
import { GET_ALL_RATING_SUBMISSIONS } from "@/graphql/queries";

// Custom Components
import RatingViewItem from "@/views/Components/Rating/RatingViewItem.vue";
import CrudRatingModal from "@/views/Components/Rating/CrudRatingModal.vue";

import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function has_element(className) {
    return document.getElementsByClassName(className).length > 0;
}

function init_scrollbar(className) {
    if (has_element(className)) {
        new PerfectScrollbar(`.${className}`, { suppressScrollX: true });
    } else {
        // try to init it later in case this component is loaded async
        setTimeout(() => {
            init_scrollbar(className);
        }, 100);
    }
}

export default {
    name: "ProfileRatingWidget",
    components: {
        RatingViewItem,
        CrudRatingModal,
    },
    props: {
        rating_id: {
            type: String,
            description: "Rating ID",
            default: "UmF0aW5nTm9kZTox", // null
        },
    },
    apollo: {
        get_rating_submissions: {
            query: GET_ALL_RATING_SUBMISSIONS,
            result(data) {
                this.handle_get_rating_submissions(data);
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors);
                return false;
            },
            update(data) {
                this.apollo_data.get_rating_submissions = data;
            },
            variables() {
                return {
                    rating_id: this.rating_id,
                };
            },
            skip: true,
        },
        get_rating_submission_user: {
            query: GET_ALL_RATING_SUBMISSIONS,
            result(data) {
                this.handle_get_rating_submission_user(data);
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors);
                return false;
            },
            update(data) {
                this.apollo_data.get_rating_submission_user = data;
            },
            variables() {
                return {
                    rating_id: this.rating_id,
                    user_id: this.$store.getters.getUserIdB64,
                };
            },
            skip: true,
        },
    },
    data() {
        return {
            apollo_data: {
                get_rating_submissions: null,
                get_rating_submission_user: null,
            },
            ratings: [
                // {
                //     id: "xxx",
                //     title: "Great Property Manager",
                //     stars: 3,
                //     comment:
                //         "Great Property Manager, communicates well and manages tenants well. Could be more responsive. ",
                //     date: "14 Jan 2022",
                // },
            ],
            modals: {
                crud_rating: {
                    show: false,
                },
            },
            user_rating_submission_id: null,
        };
    },
    methods: {
        // Scroll bar
        init_scrollbar() {
            let isWindows = navigator.platform.startsWith("Win");
            if (isWindows) {
                init_scrollbar("rating-item-list");
            }
        },
        get_max_height_ratings_container() {
            let ratings_content_height = 0;
            let rating_elements = document.getElementsByName("rating");
            for (let i = 0; i < rating_elements.length; i++) {
                ratings_content_height += rating_elements[i].height;
            }

            if (ratings_content_height < 750) {
                document.getElementById("rating-container").style.height =
                    ratings_content_height + "px";
            } else {
                document.getElementById("rating-container").style.height =
                    "750px";
            }
        },

        // Apollo Handlers
        handle_get_rating_submissions(data) {
            let flattened = graph_utils.flatten_objects_recursive(
                graph_utils.apollo_to_obj_recursive(data.data)
            );
            if (flattened.allRatingRatingSubmission.length > 0) {
                this.ratings = [];
                for (
                    let i = 0;
                    i < flattened.allRatingRatingSubmission.length;
                    i++
                ) {
                    this.ratings.push({
                        id: flattened.allRatingRatingSubmission[i].id,
                        title: flattened.allRatingRatingSubmission[i].headline,
                        stars: Number(
                            flattened.allRatingRatingSubmission[i].stars
                        ),
                        comment: flattened.allRatingRatingSubmission[i].comment,
                        date: this.display_date(
                            flattened.allRatingRatingSubmission[i].modified
                        ),
                    });
                }
                this.enable_get_rating_submission_user();
            }
        },
        handle_get_rating_submission_user(data) {
            let flattened = graph_utils.flatten_objects_recursive(
                graph_utils.apollo_to_obj_recursive(data.data)
            );
            if (flattened.allRatingRatingSubmission.length > 0) {
                this.ratings.splice(0, 0, {
                    id: flattened.allRatingRatingSubmission[0].id,
                    title: flattened.allRatingRatingSubmission[0].headline,
                    stars: Number(flattened.allRatingRatingSubmission[0].stars),
                    comment: flattened.allRatingRatingSubmission[0].comment,
                    date: this.display_date(
                        flattened.allRatingRatingSubmission[0].modified
                    ),
                });

                this.user_rating_submission_id =
                    flattened.allRatingRatingSubmission[0].id;

                this.ratings = utils.deduped_array_of_objects(
                    this.ratings,
                    "id"
                );
            }
        },
        // Apollo Triggers
        enable_get_rating_submissions() {
            // this.$apollo.queries.get_rating_submissions.setOptions({
            //     fetchPolicy: "network-only",
            // });

            if (!this.$apollo.queries.get_rating_submissions.skip) {
                this.$apollo.queries.get_rating_submissions.refetch();
            } else {
                this.$apollo.queries.get_rating_submissions.skip = false;
                this.$apollo.queries.get_rating_submissions.start();
            }
        },
        enable_get_rating_submission_user() {
            this.$apollo.queries.get_rating_submission_user.setOptions({
                fetchPolicy: "cache-and-network",
            });

            if (!this.$apollo.queries.get_rating_submission_user.skip) {
                this.$apollo.queries.get_rating_submission_user.refetch();
            } else {
                this.$apollo.queries.get_rating_submission_user.skip = false;
                this.$apollo.queries.get_rating_submission_user.start();
            }
        },

        trigger_rating_id_queries() {
            if (this.rating_id) {
                this.enable_get_rating_submissions();
            }
        },

        display_date(date_string) {
            // Converts: to:
            let date = new Date(date_string);
            return date.toISOString().split("T")[0];
        },

        show_crud_modal() {
            this.modals.crud_rating.show = true;
        },

        // Event handlers
        handle_created_rating_submission() {
            this.trigger_rating_id_queries();
        },
    },
    mounted() {
        this.init_scrollbar();
        this.trigger_rating_id_queries();
    },
    watch: {
        rating_id() {
            this.trigger_rating_id_queries();
        },
        ratings() {
            setTimeout(() => {
                this.get_max_height_ratings_container();
            }, 200);
        },
    },
    created() {
        global_event_emitter.$on("service_provider_rating_create", () => {
            setTimeout(() => {
                this.trigger_rating_id_queries();
            }, 500);
        });
    },
    beforeDestroy() {
        global_event_emitter.$off("service_provider_rating_create");
    },
};
</script>

<style>
#rating-container {
    /* background-color: rgb(255, 255, 255); */
    position: relative;
    /* margin: 10px auto; */
    /* padding: 0px; */
    /* height: 500px; */
    overflow-y: scroll;
    overflow-x: hidden;
}

#rating-container .content {
    /* height: 900px; */
}
</style>
