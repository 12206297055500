<template>
    <div>
        <b-col sm="12" md="12" lg="12">
            <h2>Rental Income</h2>
        </b-col>
        <b-row>
            <b-col sm="12" md="12" lg="12">
                <b-card>
                    <!-- Card body -->

                    <b-card-body class="p-0">
                        <!-- Property Price -->
                        <b-row v-if="rooms.length > 0">
                            <b-col>
                                <h5>
                                    Rental Income
                                    <Transition>
                                        <i
                                            v-if="$apollo.loading"
                                            class="fas fa-spinner fa-spin"
                                        ></i>
                                    </Transition>
                                </h5>
                            </b-col>
                        </b-row>
                        <b-row v-if="rooms.length > 0">
                            <b-col>
                                <div>
                                    
                                    <el-table
                                        class="table-responsive"
                                        header-row-class-name="thead-light"
                                        :data="rooms"
                                    >
                                        <el-table-column
                                            label="Name"
                                            prop="name"
                                            min-width="200px"
                                        >
                                            <template v-slot="{ row }">
                                                <h3>{{ row.name }}</h3>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            label="Expected Income"
                                            prop="ExpectedIncome"
                                        >
                                            <template v-slot="{ row }">
                                                <h2
                                                    class="m-0 text-default pl-4"
                                                >
                                                    {{
                                                        row
                                                            .expected_rental_income
                                                            .symbol
                                                    }}
                                                    &nbsp;
                                                    {{
                                                        formatted_currency(
                                                            row
                                                                .expected_rental_income
                                                                .amount
                                                        )
                                                    }}
                                                </h2>
                                            </template>
                                        </el-table-column>
                                        <!-- <el-table-column
                                            label="Project"
                                            min-width="310px"
                                            prop="name"
                                            sortable
                                        >
                                            <template v-slot="{ row }">
                                                <b-media
                                                    no-body
                                                    class="align-items-center"
                                                >
                                                    <a
                                                        href="#"
                                                        class="avatar rounded-circle mr-3"
                                                    >
                                                        <img
                                                            alt="Image placeholder"
                                                            :src="row.img"
                                                        />
                                                    </a>
                                                    <b-media-body>
                                                        <span
                                                            class="font-weight-600 name mb-0 text-sm"
                                                            >{{
                                                                row.title
                                                            }}</span
                                                        >
                                                    </b-media-body>
                                                </b-media>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            label="Budget"
                                            prop="budget"
                                            min-width="140px"
                                            sortable
                                        >
                                        </el-table-column>

                                        <el-table-column
                                            label="Status"
                                            min-width="170px"
                                            prop="status"
                                            sortable
                                        >
                                            <template v-slot="{ row }">
                                                <badge
                                                    class="badge-dot mr-4"
                                                    type=""
                                                >
                                                    <i
                                                        :class="`bg-${row.statusType}`"
                                                    ></i>
                                                    <span class="status">{{
                                                        row.status
                                                    }}</span>
                                                </badge>
                                            </template>
                                        </el-table-column>

                                        <el-table-column
                                            label="Users"
                                            min-width="190px"
                                        >
                                            <div class="avatar-group">
                                                <a
                                                    href="#"
                                                    class="avatar avatar-sm rounded-circle"
                                                    data-toggle="tooltip"
                                                    data-original-title="Ryan Tompson"
                                                >
                                                    <img
                                                        alt="Image placeholder"
                                                        src="img/theme/team-1.jpg"
                                                    />
                                                </a>
                                                <a
                                                    href="#"
                                                    class="avatar avatar-sm rounded-circle"
                                                    data-toggle="tooltip"
                                                    data-original-title="Romina Hadid"
                                                >
                                                    <img
                                                        alt="Image placeholder"
                                                        src="img/theme/team-2.jpg"
                                                    />
                                                </a>
                                                <a
                                                    href="#"
                                                    class="avatar avatar-sm rounded-circle"
                                                    data-toggle="tooltip"
                                                    data-original-title="Alexander Smith"
                                                >
                                                    <img
                                                        alt="Image placeholder"
                                                        src="img/theme/team-3.jpg"
                                                    />
                                                </a>
                                                <a
                                                    href="#"
                                                    class="avatar avatar-sm rounded-circle"
                                                    data-toggle="tooltip"
                                                    data-original-title="Jessica Doe"
                                                >
                                                    <img
                                                        alt="Image placeholder"
                                                        src="img/theme/team-4.jpg"
                                                    />
                                                </a>
                                            </div>
                                        </el-table-column>

                                        <el-table-column
                                            label="Completion"
                                            prop="completion"
                                            min-width="240px"
                                            sortable
                                        >
                                            <template v-slot="{ row }">
                                                <div
                                                    class="d-flex align-items-center"
                                                >
                                                    <span
                                                        class="completion mr-2"
                                                        >{{
                                                            row.completion
                                                        }}%</span
                                                    >
                                                    <div>
                                                        <base-progress
                                                            :type="
                                                                row.statusType
                                                            "
                                                            :value="
                                                                row.completion
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column min-width="180px">
                                            <template>
                                                <el-dropdown
                                                    trigger="click"
                                                    class="dropdown"
                                                >
                                                    <span
                                                        class="btn btn-sm btn-icon-only text-light"
                                                    >
                                                        <i
                                                            class="fas fa-ellipsis-v mt-2"
                                                        ></i>
                                                    </span>
                                                    <el-dropdown-menu
                                                        class="dropdown-menu dropdown-menu-arrow show"
                                                        slot="dropdown"
                                                    >
                                                        <b-dropdown-item
                                                            >Action</b-dropdown-item
                                                        >
                                                        <b-dropdown-item
                                                            >Another
                                                            action</b-dropdown-item
                                                        >
                                                        <b-dropdown-item
                                                            >Something else
                                                            here</b-dropdown-item
                                                        >
                                                    </el-dropdown-menu>
                                                </el-dropdown>
                                            </template>
                                        </el-table-column> -->
                                    </el-table>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row class="mt-4">
                            <b-col md="8">
                                <h1 class="text-orange" >Rental Income*</h1>
                            </b-col>
                            <b-col md="4" class="text-md-right">
                                <h1 class="mr-md-1 pr-md-5 text-orange text-nowrap" >
                                    {{ total_rental_income.symbol }}&nbsp;{{
                                        formatted_currency(
                                            total_rental_income.amount
                                        )
                                    }}
                                </h1>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="pt-4">
                                <h6 class="small">
                                    *Amounts shown are estimates calculated on
                                    available information.
                                </h6>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
// Component imports
import {
    Table,
    TableColumn,
    DropdownMenu,
    DropdownItem,
    Dropdown,
} from "element-ui";

export default {
    name: "ListingRentalIncome",
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Dropdown.name]: Dropdown,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
    },

    props: {
        subunits: {
            type: Array,
            description: "An array of rooms",
            default() {
                return [
                    {
                        // id: "U3VidW5pdE5vZGU6MTA=",
                        // expectedRentalIncome__amount: 5000,
                        // expectedRentalIncome__currency__symbol: "R",
                        // description: "",
                        // children__totalCount: 2,
                        // subunitType__friendlyName: "Main",
                    },
                ];
            },
        },
        total_rental_income: {
            type: Object,
            description: "Expected rental income",
            default() {
                return {
                    symbol: "R",
                    amount: 0,
                };
            },
        },
    },

    data() {
        return {
            rooms: [
                // {
                //     name: "Testroom1",
                //     expected_rental_income: {
                //         symbol: "R",
                //         amount: 2000,
                //     },
                // },
            ],
        };
    },
    methods: {
        build_rooms_array() {
            this.rooms = [];
            this.subunits.forEach((subunit) => {
                if (subunit.subunitType__name == "ROOM") {
                    this.rooms.push({
                        name: subunit.description,
                        id: subunit.id,
                        expected_rental_income: {
                            symbol: subunit.expectedRentalIncome__currency__symbol,
                            amount: subunit.expectedRentalIncome__amount,
                        },
                    });
                }
            });
        },

        formatted_currency(value) {
            return value.toLocaleString();
        },
    },
    watch: {
        // Prop watchers
        subunits() {
            this.build_rooms_array();
        },
    },
};
</script>
<style></style>
